import React from "react";
import { ManufacturerNavigator } from ".";
import { ManufacturerListScreen } from "../manufacturer/ManufacturerListScreen";

export const ManufacturerStack = () => {
    return (
        <ManufacturerNavigator.Navigator
            screenOptions={{ headerShown: false }}
            initialRouteName="ManufacturerListScreen"
        >
            <ManufacturerNavigator.Screen
                name="ManufacturerListScreen"
                component={ManufacturerListScreen}
            />
        </ManufacturerNavigator.Navigator >
    );
};
