import { AddManufacturerDto } from "@juicy/models/dto";
import {
    Autocomplete,
    AutocompleteItem,
    Button,
    Icon,
    Input
} from "@ui-kitten/components";
import React from "react";
import { Option } from "../../common/autocomplete";
import { useCreateManufacturer } from "./manufacturerHooks";
import { ModalNewManufacturer } from "./ModalNewManufacturer";

export const ManufacturerForm: React.FC = () => {
    const {
        modalVisible,
        setModalVisible,
        save,
        isLoading,
        options,
        onInputChange,
        selectedOption,
        setSelectedOption,
        searchString,
    } = useCreateManufacturer();

    const onSelect = (index: number) => {
        if (options[index].value === "-1") {
            setModalVisible(true);
        } else {
            setSelectedOption(options[index]);
        }
    };

    const handleSave = async (manufacturerDto: AddManufacturerDto) => {
        const manufacturer = await save(manufacturerDto);
        setSelectedOption({
            value: manufacturer.id.toString(),
            label: manufacturer.longname,
            selected: true,
        });
    };

    const renderOption = (item: Option, index: number) => (
        <AutocompleteItem key={index} title={item.label} />
    );

    const renderContent = () => {
        if (selectedOption) {
            return (
                <Input
                    label="Manufacturer"
                    onFocus={() => setSelectedOption(null)}
                    value={selectedOption.label}
                    accessoryRight={(props) => (
                        <Button
                            size="tiny"
                            appearance="ghost"
                            {...props}
                            accessoryRight={(props) => (
                                <Icon {...props} name="close" />
                            )}
                            onPress={() => setSelectedOption(null)}
                        />
                    )}
                />
            );
        }
        return (
            <Autocomplete
                autoFocus
                placeholder="Start typing..."
                onChangeText={onInputChange}
                value={searchString}
                onSelect={onSelect}
                label="Choose a manufacturer"
            >
                {options.map(renderOption)}
            </Autocomplete>
        );
    };

    return (
        <>
            {renderContent()}
            <ModalNewManufacturer
                isVisible={modalVisible}
                onClose={() => setModalVisible(false)}
                onSave={handleSave}
                initialValues={{
                    shortname:
                        searchString.length > 3
                            ? searchString.substring(0, 3)
                            : searchString,
                    longname: searchString,
                }}
            />
        </>
    );
};
