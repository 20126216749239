import { AddManufacturerDto } from "@juicy/models/dto";
import {
    Button,
    Icon,
    Input,
    Layout,
    Text,
    TopNavigation,
    TopNavigationAction
} from "@ui-kitten/components";
import React, { useEffect, useRef, useState } from "react";
import { View } from "react-native";
import Modal from "react-native-modal";
import { Divider } from "react-native-paper";

interface ModalNewManufacturerProps {
    isVisible: boolean;
    onClose: () => void;
    onSave: (manufacturerDto: AddManufacturerDto) => void;
    initialValues?: AddManufacturerDto;
}

export const ModalNewManufacturer: React.FC<ModalNewManufacturerProps> = ({
    isVisible,
    onClose,
    onSave,
    initialValues,
}) => {

    const [shortname, setShortname] = useState<string>(
        initialValues?.shortname || ""
    );
    const [longname, setLongname] = useState<string>(
        initialValues?.longname || ""
    );
    const [isValid, setIsValid] = useState(false);

    const visible = useRef<boolean>(false);

    const handleSave = () => {
        if (isValid) {
            onSave({ shortname, longname });
            onClose();
        }
    };

    useEffect(() => {
        if (
            shortname &&
            shortname.length > 2 &&
            longname &&
            longname.length > 2
        ) {
            setIsValid(true);
        }
    }, [shortname, longname]);

    useEffect(() => {
        if (isVisible && visible.current === false) {
            visible.current = true;
            setLongname(initialValues?.longname || "");
            setShortname(initialValues?.shortname || "");
        } else {
            visible.current = false;
        }
    }, [isVisible]);

    return (
        <Modal isVisible={isVisible}>
            <Layout style={{ flex: 0.75, borderRadius: 4 }} level="1">
                <TopNavigation
                    title="New Manufacturer"
                    accessoryLeft={(props) => (
                        <TopNavigationAction
                            {...props}
                            onPress={onClose}
                            icon={(props) => (
                                <Icon {...props} name="arrow-back" />
                            )}
                        />
                    )}
                />
                <Divider />
                <Layout style={{ flex: 1, padding: 16 }}>
                    <View style={{ marginBottom: 16 }}>
                    <Input
                        label="Short Name"
                        value={shortname}
                        onChangeText={setShortname}
                    />

                    </View>
                    <View style={{ marginBottom: 16 }}>
                        <Input
                            label="Long Name Name"
                            value={longname}
                            onChangeText={setLongname}
                        />

                    </View>
                    <View style={{ marginBottom: 16, minHeight: 24 }}>
                        {!isValid && (
                            <Text category="c1" status="danger">
                                A short and long name are required.
                            </Text>
                        )}
                    </View>
                    <View style={{marginBottom: 16, flexDirection: "row", justifyContent: "center"}}>
                        <Button onPress={onClose} status="basic" appearance="ghost" style={{flex: 1}}>Cancel</Button>
                        <Button disabled={!isValid} onPress={handleSave} style={{flex: 2, marginLeft: 16}}>
                            Save
                        </Button>
                    </View>
                </Layout>
            </Layout>
        </Modal>
    );
};
