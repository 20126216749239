import React, { useState } from "react";
import { ScreenLayout } from "../common/layout/ScreenLayout";
import { Mixer } from "../mixer/Mixer";

export const BatchCreateScreen = () => {
    const [value, setValue] = useState<number | number[]>(0.2);

    return (
        <ScreenLayout scroll={true}>
            <Mixer />
        </ScreenLayout>
    );
};
