import React from "react";
import { SafeAreaView, ScrollView, StyleSheet } from "react-native";
import { Container } from "./Boxes";

interface ScreenLayoutProps {
    children: JSX.Element | JSX.Element[];
    title?: string;
    subtitle?: string;
    showHeader?: boolean;
    scroll: boolean;
}

export const ScreenLayout: React.FC<ScreenLayoutProps> = ({ children, scroll }) => {
    return (
        <SafeAreaView style={styles.container}>
            <Container>
                {scroll ? <ScrollView style={{ flex: 1, paddingBottom: 30 }}>{children}</ScrollView> : children}
            </Container>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginTop: 56,
    },
});
