import { useEffect, useRef, useState } from "react";
import { apiService } from "../../../App";
import { Option } from "../../common/autocomplete";

export const useMixerFlavor = () => {
    const [flavorOptions, setFlavorOptions] = useState<Option[]>([]);
    const [selectedFlavorOption, setSelectedFlavorOption] = useState<Option | undefined>(undefined);
    const [searchString, setSearchString] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const timer = useRef<NodeJS.Timer | null>(null);

    useEffect(() => {
        const searchFlavor = async () => {
            setIsLoading(true);
            if (timer.current !== null) {
                clearTimeout(timer.current);
            }
            timer.current = setTimeout(async () => {
                const res = await apiService.flavors.list(1, { search: searchString });
                const options: Option[] = res.list.map((flavor) => ({
                    value: flavor.id.toString(),
                    label: flavor.fullName,
                    selected: false,
                }));
                setFlavorOptions(options);
            }, 500);
            setIsLoading(false);
        };
        void searchFlavor();
    }, [searchString]);

    const onSelect = (index: number) => {
        const newOptions = flavorOptions.map((option, i) => ({
            ...option,
            selected: i === index,
        }));
        setFlavorOptions(newOptions);
        setSelectedFlavorOption(newOptions[index]);
    };

    return {
        flavorOptions,
        onChangeInput: (value: string) => setSearchString(value),
        selectedFlavorOption,
        isLoading,
        onSelect,
        searchString,
    };
};
