import React from "react";
import { FlavorNavigator } from ".";
import { FlavorCreateScreen } from "../flavor/FlavorCreateScreen";
import { FlavorEditScreen } from "../flavor/FlavorEditScreen";
import { FlavorListScreen } from "../flavor/FlavorListScreen";

export const FlavorStack = () => {
    return (
        <FlavorNavigator.Navigator
            screenOptions={{ headerShown: false }}
            initialRouteName="FlavorListScreen"
        >
            <FlavorNavigator.Screen
                name="FlavorListScreen"
                component={FlavorListScreen}
            />
            <FlavorNavigator.Screen
                name="FlavorCreateScreen"
                component={FlavorCreateScreen}
            />
            <FlavorNavigator.Screen
                name="FlavorEditScreen"
            >
                {(props) => <FlavorEditScreen {...props.route} />}
            </FlavorNavigator.Screen>
        </FlavorNavigator.Navigator>
    );
};
