import { Button, Card, Text } from "@ui-kitten/components";
import React from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { Divider } from "react-native-paper";
import { Table } from "../common/table";
import { MixerIngredientForm } from "./mixer-flavor/MixeIngredientForm";
import { MixerSlider } from "./MixerSlider";
import { useMixer } from "./useMixer";
import { useMixerIngredients } from "./useMixerIngredients";

export const Mixer = () => {
    const {
        baseNicStrength,
        setBaseNicStrength,
        baseVg,
        setBaseVg,
        targetNicStrength,
        setTargetNicStrength,
        targetVg,
        setTargetVg,
        batchSize,
        setBatchSize,
        result,
    } = useMixer();

    const { ingredientFormValues, onAddIngredient, onIngredientChange, onRemoveIngredient } = useMixerIngredients();

    const renderIngredientForms = () => {
        return ingredientFormValues.map((ingredientFormValue) => {
            return (
                <View style={{ marginBottom: 16 }} key={ingredientFormValue._id}>
                    <MixerIngredientForm
                        values={ingredientFormValue}
                        onChange={(values) => onIngredientChange(values)}
                        onRemove={() => onRemoveIngredient(ingredientFormValue._id)}
                    />
                    <Divider style={{ marginTop: 8 }} />
                </View>
            );
        });
    };

    return (
        <View>
            <View style={styles.section}>
                <Text category="h6" style={styles.sectionTitle}>
                    Base
                </Text>
                <Card style={{ overflow: "visible" }}>
                    <View style={styles.sliderContainer}>
                        <Text style={styles.sliderTitle}>Nicotine Strenght</Text>
                        <MixerSlider
                            unit="mg/ml"
                            max={200}
                            value={baseNicStrength}
                            onValueChange={setBaseNicStrength}
                        />
                    </View>
                    <View style={styles.sliderContainer}>
                        <Text style={styles.sliderTitle}>VG</Text>
                        <MixerSlider max={100} value={baseVg} onValueChange={setBaseVg} />
                    </View>
                </Card>
            </View>
            <View style={styles.section}>
                <Text category="h6" style={styles.sectionTitle}>
                    Target
                </Text>
                <Card style={{ overflow: "visible" }}>
                    <View style={styles.sliderContainer}>
                        <Text style={styles.sliderTitle}>Nicotine Strenght</Text>
                        <MixerSlider
                            max={30}
                            unit="mg/ml"
                            value={targetNicStrength}
                            onValueChange={setTargetNicStrength}
                        />
                    </View>
                    <View style={styles.sliderContainer}>
                        <Text style={styles.sliderTitle}>VG</Text>
                        <MixerSlider max={100} value={targetVg} onValueChange={setTargetVg} />
                    </View>
                    <View style={styles.sliderContainer}>
                        <Text style={styles.sliderTitle}>Batch Sizes</Text>
                        <MixerSlider max={200} unit="ml" value={batchSize} onValueChange={setBatchSize} />
                    </View>
                </Card>
            </View>
            <View style={styles.section}>
                <ScrollView style={{ flex: 1 }}>
                    <Text category="h6" style={styles.sectionTitle}>
                        Ingredients
                    </Text>
                    <Card style={{ overflow: "visible" }}>
                        <View style={{ marginBottom: 32 }}>{renderIngredientForms()}</View>
                        <View style={{ height: 56, alignItems: "center", justifyContent: "center" }}>
                            <Button size="small" onPress={onAddIngredient}>
                                + Add Ingredient
                            </Button>
                        </View>
                    </Card>
                </ScrollView>
            </View>
            <View style={styles.section}>
                <Text category="h6" style={styles.sectionTitle}>
                    Result
                </Text>
                <Card style={{ overflow: "visible" }}>{!!result && <Table result={result} />}</Card>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    section: {
        marginBottom: 32,
    },
    sectionTitle: {
        fontWeight: "400",
    },
    sliderContainer: {
        marginBottom: 16,
    },
    sliderTitle: {
        fontWeight: "400",
        textAlign: "center",
        marginBottom: 8,
    },
});
