import { Button, Icon, Text } from "@ui-kitten/components";
import React from "react";
import { View } from "react-native";
import { DataList } from "../common/dataList/DataList";
import { DataListType, useDataList } from "../common/dataList/useDataList";
import { ScreenLayout } from "../common/layout/ScreenLayout";
import { RecipeListItem } from "./RecipeListItem";

export const RecipeListScreen: React.FC = () => {
    const { data, isLoading, isLoadingMore, loadMore, refresh } = useDataList(DataListType.Recipe);

    return (
        <ScreenLayout scroll={false} title="Recipes">
            <View style={{ flex: 1, padding: 8 }}>
                <View
                    style={{
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        height: 56,
                        alignItems: "center",
                    }}
                >
                    <Text category="h4" style={{ fontWeight: "600" }}>
                        RECIPES
                    </Text>
                    <Button
                        onPress={refresh}
                        appearance="ghost"
                        status="basic"
                        accessoryLeft={(props) => <Icon {...props} name="refresh" />}
                    />
                </View>
                <DataList
                    data={data}
                    renderItem={({ item }) => (
                        <View style={{ marginBottom: 8 }}>
                            <RecipeListItem recipe={item} />
                        </View>
                    )}
                    isLoading={isLoading}
                    isLoadingMore={isLoadingMore}
                    loadMore={loadMore}
                    refresh={refresh}
                    keyExtractor={(item) => `${item.id}${item.version}`}
                />
            </View>
        </ScreenLayout>
    );
};
