import { Button, Card, Divider, Input, Spinner, Text } from "@ui-kitten/components";
import React from "react";
import { View } from "react-native";
import { ScreenLayout } from "../common/layout/ScreenLayout";
import { ManufacturerForm } from "./manufacturer/ManufacturerForm";
import { useCreateFlavor } from "./useCreateFlavor";

export const FlavorCreateScreen = () => {
    const { setName, name, isValid, isSaving, saveAndCreateAnother, saveAndGoBack } = useCreateFlavor();

    return (
        <ScreenLayout scroll={true}>
            <View style={{ padding: 16 }}>
                <Text category="h4">Add new flavor</Text>
                <Card status="primary" style={{ marginTop: 16 }}>
                    <ManufacturerForm />
                    <Divider style={{ marginVertical: 16 }} />
                    <Input
                        label="Name"
                        placeholder="Give your flavor a name"
                        value={name}
                        onChangeText={setName}
                        style={{ marginBottom: 16 }}
                    />
                    <Divider style={{ marginVertical: 16 }} />
                    <View style={{ flexDirection: "row" }}>
                        <Button
                            onPress={isSaving ? () => null : saveAndGoBack}
                            disabled={!isValid}
                            style={{ flex: 0.7, marginRight: 8 }}
                            size="small"
                            status="basic"
                            accessoryRight={(props) =>
                                isSaving ? <Spinner {...props} size="small" status="control" /> : <></>
                            }
                        >
                            {(props) => <Text {...props}>{!isSaving ? "Save" : "Saving..."}</Text>}
                        </Button>
                        <Button
                            onPress={isSaving ? () => null : saveAndCreateAnother}
                            disabled={!isValid}
                            size="small"
                            style={{ flex: 1 }}
                            accessoryRight={(props) =>
                                isSaving ? <Spinner {...props} size="small" status="control" /> : <></>
                            }
                        >
                            {(props) => <Text {...props}>{!isSaving ? "Save and add another" : "Saving..."}</Text>}
                        </Button>
                    </View>
                </Card>
            </View>
        </ScreenLayout>
    );
};
