import { Flavor } from "@juicy/models";
import { FlavorDto } from "@juicy/models/dto";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useRef, useState } from "react";
import { useToast } from "react-native-toast-notifications";
import { useRecoilState, useSetRecoilState } from "recoil";
import { apiService } from "../../App";
import { DataListType, useDataList, useUpdateDataList } from "../common/dataList/useDataList";
import { FlavorNavigation } from "../navigation";
import { ManufacturerSearchStringAtom, ManufacturerSelectedOptionAtom } from "./manufacturer/manufacturerAtoms";

export const useFlavor = (flavorId?: number) => {
    const navigation = useNavigation<FlavorNavigation>();
    const [selectedManufacturerOption, setSelectedManufacturerOption] = useRecoilState(ManufacturerSelectedOptionAtom);
    const setSearchString = useSetRecoilState(ManufacturerSearchStringAtom);
    const [isValid, setIsValid] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isFormPristine, setIsFormPristine] = useState(true);
    const { data, setData } = useDataList(DataListType.Flavor);

    const isFirstLoad = useRef(true);
    const flavorIdRef = useRef<number | undefined>(undefined);
    const [selectedFlavor, setSelectedFlavor] = useState<Flavor | null>(null);
    const [name, setName] = useState("");

    const { add } = useUpdateDataList(DataListType.Flavor);

    const toast = useToast();

    useEffect(() => {
        const getFlavorFromListData = (flavorId: number) => {
            return data.list.find((x) => x.id === flavorId);
        };
        const getFlavor = async () => {
            let flavor: Flavor | null = getFlavorFromListData(flavorId!);
            if (!flavor) {
                setIsLoading(true);
                flavor = await apiService.flavors.getOne(flavorId!);
            }
            setSelectedFlavor(flavor);
            setName(flavor.name);
            setSelectedManufacturerOption({
                label: flavor.manufacturer.shortname + " - " + flavor.manufacturer.longname,
                value: flavor.manufacturer.id.toString(),
                selected: true,
            });
            flavorIdRef.current = flavorId;
            setIsLoading(false);
        };
        if (flavorId && flavorId !== flavorIdRef.current) {
            void getFlavor();
        }
    }, [flavorId, selectedManufacturerOption, setSelectedManufacturerOption, data.list]);

    useEffect(() => {
        if (isFirstLoad.current) {
            isFirstLoad.current = false;
            return;
        }
        setIsFormPristine(false);
        setIsValid(name.length > 0 && !!selectedManufacturerOption);
    }, [name, selectedManufacturerOption]);

    const save = async () => {
        if (isValid && selectedManufacturerOption) {
            setIsSaving(true);
            let successMessage = "Flavor created";
            let res: Flavor | null;
            if (selectedFlavor) {
                res = await apiService.flavors.edit({
                    ...selectedFlavor,
                    name,
                    manufacturerId: +selectedManufacturerOption?.value,
                });
                successMessage = "Flavor updated";
                updateDataList(res);
            } else {
                res = await apiService.flavors.create({
                    name,
                    manufacturerId: +selectedManufacturerOption?.value,
                });
                add(res);
            }
            setSelectedFlavor(res);
            setSearchString("");
            toast.show(successMessage, {
                type: "success",
            });
            setIsSaving(false);
        }
    };

    const updateDataList = (flavorDto: FlavorDto) => {
        setData((prev) => {
            const newList = [...prev.list];
            const index = newList.findIndex((x) => x.id === flavorDto.id);
            if (index !== -1) {
                newList[index] = flavorDto;
                console.log(newList[index]);
            }
            return { ...prev, list: newList };
        });
    };

    const saveAndGoBack = async () => {
        await save();
        if (navigation.canGoBack()) {
            navigation.goBack();
        } else {
            navigation.navigate("FlavorListScreen");
        }
    };

    const saveAndCreateAnother = async () => {
        await save();
        setName("");
        setSelectedManufacturerOption(null);
    };

    const saveAndGoToList = async () => {
        await save();
        navigation.navigate("FlavorListScreen");
    };

    return {
        saveAndCreateAnother,
        saveAndGoBack,
        isValid,
        isSaving,
        name,
        setName,
        isLoading,
        isFormPristine,
        saveAndGoToList,
    };

};
