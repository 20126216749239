import { Manufacturer } from "@juicy/models";
import { AddManufacturerDto } from "@juicy/models/dto";
import { useEffect, useRef } from "react";
import { useRecoilState } from "recoil";
import { apiService } from "../../../App";
import { Option } from "../../common/autocomplete";
import {
    ManufacturerIsLoadingAtom,
    ManufacturerIsSavingAtom,
    ManufacturerModalVisibleAtom,
    ManufacturerOptionsAtom,
    ManufacturerSearchStringAtom,
    ManufacturerSelectedOptionAtom,
} from "./manufacturerAtoms";

export const useCreateManufacturer = () => {
    const [modalVisible, setModalVisible] = useRecoilState(
        ManufacturerModalVisibleAtom
    );
    const [isSaving, setIsSaving] = useRecoilState(ManufacturerIsSavingAtom);
    const [selectedOption, setSelectedOption] = useRecoilState(
        ManufacturerSelectedOptionAtom
    );
    const [options, setOptions] = useRecoilState(ManufacturerOptionsAtom);
    const [isLoading, setIsLoading] = useRecoilState(ManufacturerIsLoadingAtom);
    const [searchString, setSearchString] = useRecoilState(
        ManufacturerSearchStringAtom
    );
    const timer = useRef<NodeJS.Timer | null>(null);

    const parseManufacturerToOption = (manufacturer: Manufacturer): Option => {
        return {
            label: `${manufacturer.shortname} - ${manufacturer.longname}`,
            value: manufacturer.id.toString(),
            selected: false,
        };
    };

    const searchManufacturers = async (search: string) => {
        const res = await apiService.manufacturer.list(1, { search });
        const extraOption: Option = {
            value: "-1",
            label: `Create new manufacturer: ${searchString}`,
            selected: false,
        };
        const options = res.map(parseManufacturerToOption);
        if (searchString.length > 1) {
            setOptions([extraOption, ...options]);
        } else {
            setOptions(options);
        }
    };

    const onInputChange = async (value: string) => {
        setIsLoading(true);
        setSearchString(value);
        await searchManufacturers(value);
        setIsLoading(false);
    };

    const onOptionSelected = (option: Option) => {
        setSelectedOption(option);
    };

    useEffect(() => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        timer.current = setTimeout(async () => {
            await searchManufacturers(searchString);
        }, 500);
    }, [searchString]);

    const save = async (data: AddManufacturerDto): Promise<Manufacturer> => {
        setIsSaving(true);
        const savedManufacturer = await apiService.manufacturer.create({
            shortname: data.shortname,
            longname: data.longname,
        });
        setIsSaving(false);
        return savedManufacturer;
    };

    return {
        save,
        modalVisible,
        setModalVisible,
        isSaving,
        selectedOption,
        setSelectedOption,
        options,
        isLoading,
        searchString,
        onInputChange,
        onOptionSelected,
    };
};
