import { useCallback, useEffect, useRef } from "react";
import { useRecoilState } from "recoil";
import { apiService } from "../../../App";
import { createDataListAtoms, DataListFilter } from "./genericListAtom";

export enum DataListType {
    Recipe = "Recipe",
    Flavor = "Flavor",
    Batch = "Batch",
    Manufacturer = "Manufacturer",
}

const dataListParamMap = {
    [DataListType.Recipe]: {
        AtomSet: createDataListAtoms("Recipe"),
        apiServiceFunctionCreator: () => apiService.recipe.list,
    },
    [DataListType.Flavor]: {
        AtomSet: createDataListAtoms("Flavor"),
        apiServiceFunctionCreator: () => apiService.flavors.list,
    },
    [DataListType.Batch]: {
        AtomSet: createDataListAtoms("Batch"),
        apiServiceFunctionCreator: () => apiService.batch.list,
    },
    [DataListType.Manufacturer]: {
        AtomSet: createDataListAtoms("Manufacturer"),
        apiServiceFunctionCreator: () => apiService.manufacturer.list,
    },
};

export const useDataList = (dataListType: DataListType) => {
    const { AtomSet, apiServiceFunctionCreator } = dataListParamMap[dataListType];
    const apiServiceFunction = apiServiceFunctionCreator();

    const [listState, setListState] = useRecoilState(AtomSet.List);
    const [isLoading, setIsLoading] = useRecoilState(AtomSet.IsLoading);
    const [isLoadingMore, setIsLoadingMore] = useRecoilState(AtomSet.IsLoadingMore);
    const [filter, setFilter] = useRecoilState(AtomSet.Filter);
    const timer = useRef<NodeJS.Timeout | null>(null);

    const fetchData = useCallback(
        async (page: number, filter?: DataListFilter) => {
            return await apiServiceFunction(page, filter);
        },
        [apiServiceFunction]
    );

    const refresh = useCallback(async () => {
        console.log("refresh");
        setIsLoading(true);

        try {
            const response = await fetchData(1, filter);
            setListState({ list: response.list, page: 1, total: response.total });
        } catch (error) {
            console.log({ error });
        } finally {
            setIsLoading(false);
        }
    }, [fetchData, filter, setIsLoading, setListState]);

    const loadMore = useCallback(async () => {
        console.log("loadmore start");
        console.log({ listState });
        if (listState.total && listState.total > listState.page * 30) {
            console.log("yes, loadmore, page", listState.page + 1);
            try {
                setIsLoadingMore(true);
                const response = await fetchData(listState.page + 1, filter);
                setListState((prev) => ({
                    ...prev,
                    list: [...prev.list, ...response.list],
                    page: prev.page + 1,
                    total: response.total,
                }));
            } catch (error) {
                console.log({ error });
            } finally {
                setIsLoading(false);
            }
        }
    }, [fetchData, filter, listState, setIsLoading, setListState, setIsLoadingMore]);

    useEffect(() => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        timer.current = setTimeout(async () => {
            const response = await fetchData(1, filter);
            setListState((prev) => ({
                ...prev,
                list: response.list,
                total: response.total,
            }));
        }, 300);
    }, [filter.orderBy, filter.minimumRating, fetchData, filter, setListState]);

    useEffect(() => {
        void fetchData(1);
    }, [fetchData]);

    return {
        data: listState,
        setData: setListState,
        isLoading,
        filter,
        setFilter,
        refresh,
        loadMore,
        isLoadingMore,
    };
};

export const useUpdateDataList = (dataListType: DataListType) => {
    const { data, setData } = useDataList(dataListType);

    const add = <T>(item: T) => {
        const newList = [...data.list, item];
        setData((prev) => ({
            ...prev,
            list: newList,
            total: prev.total ? prev.total + 1 : undefined,
        }));
    };

    return {
        add,
    };
};
