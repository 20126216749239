import { useState } from "react";
import { MixerIngredientFormValues } from "./mixer-flavor/MixeIngredientForm";

const randomString = () => Math.random().toString(36).substring(2, 12);

export const useMixerIngredients = () => {
    const createEmptyMixerFormValuesElement = (): MixerIngredientFormValues => {
        return {
            _id: randomString(),
            percentage: 0,
        };
    };
    const [ingredientFormValues, setIngredientFormValues] = useState<MixerIngredientFormValues[]>([
        createEmptyMixerFormValuesElement(),
    ]);

    const onIngredientChange = (values: MixerIngredientFormValues) => {
        const index = ingredientFormValues.findIndex((i) => i._id === values._id);
        const newIngredientFormValues = [...ingredientFormValues];
        newIngredientFormValues[index] = values;
        setIngredientFormValues(newIngredientFormValues);
    };

    const onAddIngredient = () => {
        setIngredientFormValues([...ingredientFormValues, createEmptyMixerFormValuesElement()]);
    };

    const onRemoveIngredient = (_id: string) => {
        setIngredientFormValues(ingredientFormValues.filter((i) => i._id !== _id));
    };

    return {
        ingredientFormValues,
        onIngredientChange,
        onAddIngredient,
        onRemoveIngredient,
    };
};
