import { atom } from "recoil";

export interface DataList {
    list: Array<any>;
    page: number;
    total?: number;
}

export enum DataListOrderBy {
    Newest = 1, Oldest, HighestRated, LowestRated
}

export interface DataListFilter {
    minimumRating?: number;
    orderBy?: DataListOrderBy;
    search: string;
}

export const createDataListAtoms = (listType: string) => {
    const List = atom<DataList>({
        key: `DataListAtom${listType}`,
        default: {
            list: [],
            page: 1,
            total: undefined,
        },
    });

    const IsRefreshing = atom({
        key: `DataListIsRefreshingAtom${listType}`,
        default: false,
    });

    const IsLoadingMore = atom({
        key: `DataListIsLoadingMoreAtom${listType}`,
        default: false,
    });

    const IsLoading = atom({
        key: `DataListIsLoadingAtom${listType}`,
        default: false,
    });

    const Filter = atom<DataListFilter>({
        key: `DataListFilterAtom${listType}`,
        default: {
            minimumRating: undefined,
            orderBy: DataListOrderBy.Newest,
            search: ""
        },
    });

    return {
        List, IsRefreshing, IsLoadingMore, IsLoading, Filter
    };
};
