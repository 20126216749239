import { FreeformIngredientDto } from "@juicy/models/dto";
import { useEffect, useState } from "react";
import { MixerIngredientFormValues } from "./mixer-flavor/MixeIngredientForm";

const multipliers = {
    nicBase: 1.0333,
    vg: 1.2616195495927167,
    pg: 1.0361663652802893,
    flavors: 1.048148148148148,
};
export interface Result {
    nicBase: number;
    flavors: Array<{ name: string; value: number }>;
    vg: number;
    pg: number;
    sum: number;
}
export interface MixerResult {
    g: Result;
    ml: Result;
}

export const useMixer = () => {
    const [baseNicStrength, setBaseNicStrength] = useState(100);
    const [baseVg, setBaseVg] = useState(0);
    const [targetNicStrength, setTargetNicStrength] = useState(6);
    const [targetVg, setTargetVg] = useState(70);
    const [batchSize, setBatchSize] = useState(10);
    const [flavors, setFlavors] = useState<FreeformIngredientDto[]>([]);

    const [ingredients, setIngredients] = useState<MixerIngredientFormValues[]>([]);

    const [result, setResult] = useState<MixerResult | null>(null);

    useEffect(() => {
        const basePg = 100 - baseVg;
        const targetPg = 100 - targetVg;
        let remainingBatchSize = batchSize;
        console.log({ targetPg });
        const basePerBatchSize = targetNicStrength / baseNicStrength;
        console.log({ basePerBatchSize });
        const baseAmount = basePerBatchSize * batchSize;
        console.log({ baseAmount });
        const baseNicPercentage = (baseNicStrength / 1000) * 100;
        console.log({ baseNicPercentage });
        const restPercentage = 100 - baseNicPercentage;
        console.log({ restPercentage });
        const baseRest = baseAmount * (restPercentage / 100);
        console.log({ baseRest });
        const baseNic = baseAmount * (baseNicPercentage / 100);
        console.log({ baseNic });
        const baseRestVg = baseRest * (baseVg / 100);
        console.log({ baseRestVg });
        const baseRestPg = baseRest * (basePg / 100);
        console.log({ baseRestPg });
        remainingBatchSize -= baseNic;
        console.log({ remainingBatchSize });
        const remainingVg = remainingBatchSize * (targetVg / 100) - baseRestVg;
        console.log({ remainingVg });
        let remainingPg = remainingBatchSize * (targetPg / 100) - baseRestPg;
        console.log({ remainingPg });

        const recipeFlavors = flavors
            .filter((f) => !!f.name && !!f.percentage && f.percentage > 0)
            .map<{ name: string; value: number }>((f) => {
                return { name: f.name, value: batchSize * (f.percentage! / 100) } as any;
            });
        const totalFlavorsInMg = recipeFlavors.reduce((acc, f) => {
            acc += f.value;
            return acc;
        }, 0);
        remainingPg -= totalFlavorsInMg;

        const ml: Result = {
            nicBase: Math.round(baseAmount * 100) / 100,
            vg: Math.round(remainingVg * 100) / 100,
            pg: Math.round(remainingPg * 100) / 100,
            flavors: recipeFlavors,
            sum: 0,
        };

        const g: Result = {
            nicBase: ml.nicBase * multipliers.nicBase,
            vg: ml.vg * multipliers.vg,
            pg: ml.pg * multipliers.pg,
            flavors: ml.flavors.map((f) => ({ name: f.name, value: f.value * multipliers.flavors })),
            sum: 0,
        };

        g.sum = Math.round((g.nicBase + g.pg + g.vg + g.flavors.reduce((acc, v) => acc + v.value, 0)) * 100) / 100;

        ml.sum = Math.round((ml.nicBase + ml.pg + ml.vg + ml.flavors.reduce((acc, f) => acc + f.value, 0)) * 100) / 100;

        setResult({
            ml,
            g,
        });
    }, [baseNicStrength, baseVg, targetNicStrength, targetVg, batchSize, flavors]);

    return {
        baseNicStrength,
        setBaseNicStrength,
        baseVg,
        setBaseVg,
        targetNicStrength,
        setTargetNicStrength,
        targetVg,
        setTargetVg,
        batchSize,
        setBatchSize,
        flavors,
        setFlavors,
        setResult,
        result,
    };
};
