import { FreeformIngredient, Ingredient } from "@juicy/models";
import { BatchDto } from "@juicy/models/dto";
import { Card, Divider, Text } from "@ui-kitten/components";
import React, { useState, useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { apiService } from "../../App";
import { FreeformIngredientRow } from "../recipe/freeformIngredientRow";
import { IngredientRow } from "../recipe/IngredientRow";
import { getColorForRating, Rating } from "../recipe/rating";

interface BatchListItemProps {
    batch: BatchDto;
}

interface CardHeaderProps {
    batch: BatchDto;
    disabled?: boolean;
    handleSaveRating: () => void;
}

const CardHeader: React.FC<CardHeaderProps> = ({ batch, handleSaveRating, ...rest }) => {
    return (
        <View {...rest} style={styles.cardHeader}>
            <View style={{ flexDirection: "column" }}>
                <Text numberOfLines={1} ellipsizeMode="tail" category="s1">
                    {new Date(batch.createdAt).toDateString()}
                </Text>
                <Text numberOfLines={1} ellipsizeMode="tail" category="s2">
                    {batch.nicStrength}mg • {batch.vg}% VG
                </Text>
            </View>
            <Rating size="large" rating={batch.rating} onChange={handleSaveRating} />
        </View>
    );
};

export const BatchListItem: React.FC<BatchListItemProps> = ({ batch }) => {
    const [internalBatch, setInternalBatch] = useState<BatchDto>(batch);

    return useMemo(() => {
        const handleSaveRating = async (rating?: number) => {
            const newBatch = { ...batch, rating };
            await apiService.batch.edit(newBatch);
            setInternalBatch(newBatch);
        };
        return (
            <Card
                onPress={() => console.log("card pressed")}
                style={{
                    marginBottom: 5,
                    borderLeftWidth: 2,
                    borderLeftColor: getColorForRating(internalBatch.rating),
                }}
                // onPress={() => navigation.navigate("RecipeStackScreen", {
                //     screen: "RecipeShowScreen",
                //     params: {
                //         recipeId: recipe.id
                //     },
                //     initial: false
                // })}
                header={() => (
                    <CardHeader
                        handleSaveRating={handleSaveRating}
                        batch={internalBatch}
                        // disabled={disabled}
                    />
                )}
            >
                {internalBatch.recipe.name && <Text category="s1">{internalBatch.recipe.name}</Text>}
                {internalBatch.recipe.freeformIngredients &&
                    internalBatch.recipe.freeformIngredients.length > 0 &&
                    internalBatch.recipe.freeformIngredients.map((ffIngredient: FreeformIngredient) => (
                        <View key={ffIngredient.id}>
                            <FreeformIngredientRow freeformIngredient={ffIngredient} />
                            <Divider />
                        </View>
                    ))}
                {internalBatch.recipe.ingredients &&
                    internalBatch.recipe.ingredients.length > 0 &&
                    internalBatch.recipe.ingredients.map((ingredient: Ingredient) => (
                        <View key={`${ingredient.recipeId}-${ingredient.recipeVersion}-${ingredient.flavorId}`}>
                            <IngredientRow ingredient={ingredient} />
                            <Divider />
                        </View>
                    ))}
            </Card>
        );
    }, [internalBatch, batch]);
};

const styles = StyleSheet.create({
    cardHeader: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 16,
        paddingRight: 16,
    },
    topVersionBadge: {
        backgroundColor: "#efe0ff",
        border: "1px solid #5d19a6",
        borderRadius: 4,
        paddingTop: 1,
        paddingBottom: 1,
        paddingRight: 5,
        paddingLeft: 5,
    },
    subtitle: {
        display: "flex",
        flexDirection: "row",
        marginTop: 4,
    },
});
