import React, { useRef } from "react";
import { CreatableAutocompleteAsync, Option } from "../../common/autocomplete";
import { useMixerFlavor } from "./useMixerFlavor";

interface MixerFlavorCreatableAutocompleteProps {
    onSelectFlavor: (option: Option) => void;
    selectedFlavorOption?: Option;
}

export const MixerFlavorCreatableAutocomplete: React.FC<MixerFlavorCreatableAutocompleteProps> = ({
    onSelectFlavor,
    selectedFlavorOption,
}) => {
    const { flavorOptions, isLoading, onChangeInput, onSelect, searchString } = useMixerFlavor();

    const handleSelect = (index: number) => {
        onSelectFlavor(flavorOptions[index]);
        onSelect(index);
    };

    return (
        <CreatableAutocompleteAsync
            isLoading={isLoading}
            options={flavorOptions}
            onChangeInput={onChangeInput}
            selectedOption={selectedFlavorOption}
            placeholder="Start typing..."
            onSelect={handleSelect}
            value={searchString}
        />
    );
};
