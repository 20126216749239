import { Button, ButtonGroup, Card, Datepicker, Text } from "@ui-kitten/components";
import React from "react";
import { StyleSheet, View } from "react-native";
import { DataListOrderBy } from "../common/dataList/genericListAtom";
import { DataListType, useDataList } from "../common/dataList/useDataList";
import { Rating } from "../recipe/rating";

export const FlavorFilters = () => {
    const { filter, setFilter } = useDataList(DataListType.Flavor);

    const handleOrderByChange = (newOrderBy: DataListOrderBy) => {
        setFilter((prev) => ({ ...prev, orderBy: newOrderBy }));
    };

    const handleRatingChange = (newRating?: number) => {
        setFilter((prev) => ({ ...prev, minimumRating: newRating }));
    };

    return (
        <View style={styles.root}>
            <Card
                status="info"
                appearance="outline"
            >
                <View style={styles.cardInner}>
                    <Text
                        appearance="hint"
                        category="label"
                        style={{ marginBottom: 5 }}
                    >
                        Sort by
                    </Text>
                    <ButtonGroup
                        appearance="filled"
                        size="tiny"
                        status="basic"
                    >
                        <Button
                            style={filter.orderBy === DataListOrderBy.Newest && styles.selectedButton}
                            onPress={() => handleOrderByChange(DataListOrderBy.Newest)}
                        >
                            Newest
                        </Button>
                        <Button
                            style={filter.orderBy === DataListOrderBy.Oldest && styles.selectedButton}
                            onPress={() => handleOrderByChange(DataListOrderBy.Oldest)}
                        >
                            Oldest
                        </Button>
                        <Button
                            style={filter.orderBy === DataListOrderBy.HighestRated && styles.selectedButton}
                            onPress={() => handleOrderByChange(DataListOrderBy.HighestRated)}
                        >
                            Highest rated
                        </Button>
                        <Button
                            style={filter.orderBy === DataListOrderBy.LowestRated && styles.selectedButton}
                            onPress={() => handleOrderByChange(DataListOrderBy.LowestRated)}
                        >
                            Lowest rated
                        </Button>
                    </ButtonGroup>
                </View>
                <View style={styles.cardInner}>
                    <Text
                        appearance="hint"
                        category="label"
                        style={{ marginBottom: 5, marginTop: 5 }}
                    >
                        Date
                    </Text>
                    <View style={styles.datepickerContaier}>
                        <Datepicker
                            style={{ width: "100%", marginRight: 10 }}
                            placeholder="From"
                            size="small"
                            date={undefined}
                        // onSelect={nextDate => setDate(nextDate)}
                        />
                        <Datepicker
                            style={{ width: "100%" }}
                            placeholder="To"
                            size="small"
                            date={undefined}
                        // onSelect={nextDate => setDate(nextDate)}
                        />
                    </View>
                </View>
                <View style={styles.cardInner}>
                    <Text
                        appearance="hint"
                        category="label"
                        style={{ marginBottom: 5, marginTop: 10 }}
                    >
                        Minimum rating
                    </Text>
                    <Rating size="small" rating={filter.minimumRating} onChange={handleRatingChange} />
                </View>
            </Card>
        </View>
    );
};

const styles = StyleSheet.create({
    root: {
        marginTop: 10,
        marginBottom: 10
    },
    cardInner: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    selectedButton: {
        backgroundColor: "#ccffcc"
    },
    datepickerContaier: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
    }
});