import { RouteProp, useRoute } from "@react-navigation/native";
import { Button, Divider, Spinner, Text } from "@ui-kitten/components";
import React from "react";
import { View } from "react-native";
import { ScreenLayout } from "../common/layout/ScreenLayout";
import { FlavorStackParamList } from "../navigation";
import { FlavorForm } from "./FlavorForm";
import { useFlavor } from "./useFlavor";

export const FlavorEditScreen = () => {
    const { params } = useRoute<RouteProp<FlavorStackParamList>>();

    const { isFormPristine, setName, isSaving, saveAndGoToList, isValid, name } = useFlavor(params!.flavorId);

    return (
        <ScreenLayout scroll={true}>
            <View>
                <Text category="h1">{name}</Text>
                <FlavorForm onNameChange={setName} name={name} />
                <Divider style={{ marginVertical: 16 }} />
                <View>
                    <Button
                        onPress={isSaving ? () => null : saveAndGoToList}
                        disabled={!isValid || isFormPristine}
                        style={{ flex: 0.7, marginRight: 8 }}
                        size="small"
                        accessoryRight={(props) =>
                            isSaving ? <Spinner {...props} size="small" status="control" /> : <></>
                        }
                    >
                        {(props) => <Text {...props}>{!isSaving ? "Save" : "Saving..."}</Text>}
                    </Button>
                </View>
            </View>
        </ScreenLayout>
    );
};
