import AsyncStorage from "@react-native-async-storage/async-storage";
import { Avatar, Button } from "@ui-kitten/components";
import * as AuthSession from "expo-auth-session";
import * as WebBrowser from "expo-web-browser";
import jwtDecode from "jwt-decode";
import React, { useState } from "react";
import {
    Platform, TouchableOpacity, View
} from "react-native";
import { useRecoilState } from "recoil";
import { httpClient } from "../../App";
import { config } from "../../config";
import { userStateAtom } from "../user/userAtoms";
import { UserModal } from "./UserModal";

const useProxy = Platform.select({ web: false, default: true });
const redirectUri = AuthSession.makeRedirectUri({ useProxy });

WebBrowser.maybeCompleteAuthSession();

const generateShortUUID = () => {
    return Math.random().toString(36).substring(2, 15);
};

export const LoginButton = () => {
    const [userState, setUserState] = useRecoilState(userStateAtom);
    const [userModalVisible, setUserModalVisible] = useState<boolean>(false);
    console.log({ userState });

    const login = async () => {
        const state = generateShortUUID();

        const discovery = await AuthSession.fetchDiscoveryAsync(config.auth0Domain);

        const authRequestOptions: AuthSession.AuthRequestConfig = {
            usePKCE: true,
            responseType: AuthSession.ResponseType.Code,
            clientId: config.auth0ClientId,
            redirectUri: redirectUri,
            prompt: AuthSession.Prompt.Login,
            scopes: ["openid", "profile", "email", "offline_access"],
            state: state,
            extraParams: {
                access_type: "offline",
            },
        };

        const authRequest = new AuthSession.AuthRequest(authRequestOptions);

        const authorizeResult = await authRequest.promptAsync(discovery, {
            useProxy,
        });

        if (authorizeResult.type === "success") {
            const tokenResult = await AuthSession.exchangeCodeAsync(
                {
                    code: authorizeResult.params.code,
                    clientId: config.auth0ClientId,
                    redirectUri: redirectUri,
                    extraParams: {
                        code_verifier: authRequest.codeVerifier || "",
                    },
                },
                discovery
            );

            // Retrieve the JWT token and decode it
            if (tokenResult.idToken) {
                const decoded = jwtDecode(tokenResult.idToken);
                console.log(decoded);

                const { name, given_name, picture } = decoded;
                await AsyncStorage.setItem("IdToken", tokenResult.idToken);
                if (tokenResult.refreshToken) {
                    await AsyncStorage.setItem(
                        "RefreshToken",
                        tokenResult.refreshToken
                    );
                }
                await AsyncStorage.setItem("username", given_name || name);
                await AsyncStorage.setItem("picture", picture);
                await httpClient.post("/users");
                setUserState({
                    username: given_name || name,
                    picture
                });
            }
        }
    };

    const logout = async () => {
        const IdToken = await AsyncStorage.getItem("IdToken");
        if (!IdToken) return;

        await AsyncStorage.removeItem("IdToken");
        await AsyncStorage.removeItem("RefreshToken");
        await AsyncStorage.removeItem("username");
        await AsyncStorage.removeItem("picture");
        setUserState(null);
    };

    return (
        <View>
            {userState?.username ? (
                <TouchableOpacity style={{marginRight: 16}} onPress={() => setUserModalVisible(true)}>
                    <Avatar
                        source={{ uri: userState.picture || "" }}
                        size="tiny"
                    />
                    {/* <Text>{userState.username}</Text> */}
                </TouchableOpacity>
            ) : (
                <Button size="small" disabled={false} onPress={() => login()}>
                    Login
                </Button>
            )}
            <UserModal
                isVisible={userModalVisible}
                onClose={() => setUserModalVisible(false)}
                onPressLogout={logout}
                user={{
                    email: userState?.username ?? "",
                    pictureUrl: userState?.picture ?? "",
                }}
            />
        </View>
    );
};