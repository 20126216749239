import { Avatar, Layout, Text } from "@ui-kitten/components";
import React from "react";
import { StyleSheet, View } from "react-native";
import { ProfileSocial } from "./ProfileSocial";

export interface IUser {
    email: string;
    given_name?: string;
    last_name?: string;
    pictureUrl?: string;
}

interface UserProfileProps {
    user: IUser;
}

export const UserProfile: React.FC<UserProfileProps> = ({ user }) => {
    const { email, given_name, pictureUrl } = user;
    return (
        <Layout style={styles.header} level="1">
            <Avatar
                style={styles.profileAvatar}
                size="large"
                source={{ uri: pictureUrl }}
            />
            <View style={styles.profileDetailsContainer}>
                <Text category="h4" style={{ fontWeight: "400" }}>
                    {given_name ?? email}
                </Text>
                <Text
                    appearance="hint"
                    category="s1"
                    style={{ fontWeight: "300" }}
                >
                    Master Mixer
                </Text>
                <View style={styles.profileSocialsContainer}>
                    <ProfileSocial
                        style={styles.profileSocialContainer}
                        hint="Public"
                        value={`166`}
                    />
                    <ProfileSocial
                        style={styles.profileSocialContainer}
                        hint="Private"
                        value={`243`}
                    />
                    <ProfileSocial
                        style={styles.profileSocialContainer}
                        hint="Flavors"
                        value={`33`}
                    />
                </View>
            </View>
        </Layout>
    );
};

const styles = StyleSheet.create({
    header: {
        flexDirection: "row",
        marginHorizontal: -16,
        paddingHorizontal: 16,
        paddingTop: 16,
        paddingBottom: 16,
        marginBottom: 8,
        borderTopRightRadius: 4,
        borderTopLeftRadius: 4,
    },
    profileAvatar: {
        marginHorizontal: 8,
    },
    profileDetailsContainer: {
        flex: 1,
        marginHorizontal: 8,
    },
    profileSocialsContainer: {
        flexDirection: "row",
        marginTop: 24,
    },
    profileSocialContainer: {
        flex: 1,
    },
    followButton: {
        marginVertical: 16,
    },
    post: {
        margin: 8,
    },
    postHeader: {
        height: 220,
    },
    postBody: {
        flexDirection: "row",
        marginHorizontal: -8,
    },
    postAuthorContainer: {
        flex: 1,
        justifyContent: "center",
        marginHorizontal: 16,
    },
    iconButton: {
        flexDirection: "row-reverse",
        paddingHorizontal: 0,
    },
});
