import { Icon } from "@ui-kitten/components";
import React from "react";
import { BottomTabNavigator } from ".";
import { BatchStack } from "./BatchStack";
import { FlavorStack } from "./FlavorStack";
import { ManufacturerStack } from "./ManufacturerStack";
import { RecipeStack } from "./RecipeStack";

export const BottomTabStack = () => {

    return (
        <BottomTabNavigator.Navigator barStyle={{ backgroundColor: "#fff" }}>
            <BottomTabNavigator.Screen
                name="RecipeStackScreen"
                component={RecipeStack}
                options={{
                    tabBarLabel: "Recipes",
                    tabBarIcon: ({ color }) => (
                        <Icon
                            name="file"
                            fill={color}
                            style={{ width: 26, height: 26 }}
                        />
                    ),
                }}
            />
            <BottomTabNavigator.Screen
                name="BatchStackScreen"
                component={BatchStack}
                options={{
                    tabBarLabel: "Batches",
                    tabBarIcon: ({ color }) => (
                        <Icon
                            name="funnel"
                            fill={color}
                            style={{ width: 26, height: 26 }}
                        />
                    ),
                }}
            />
            <BottomTabNavigator.Screen
                name="FlavorStackScreen"
                component={FlavorStack}
                options={{
                    tabBarLabel: "Flavors",
                    tabBarIcon: ({ color }) => (
                        <Icon
                            name="smiling-face"
                            fill={color}
                            style={{ width: 26, height: 26 }}
                        />
                    ),
                }}
            />
            <BottomTabNavigator.Screen
                name="ManufacturerStackScreen"
                component={ManufacturerStack}
                options={{
                    tabBarLabel: "Manufacturers",
                    tabBarIcon: ({ color }) => (
                        <Icon
                            name="npm-outline"
                            fill={color}
                            style={{ width: 26, height: 26 }}
                        />
                    ),
                }}
            />
        </BottomTabNavigator.Navigator>
    );
};
