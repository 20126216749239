import { Spinner } from "@ui-kitten/components";
import React, { useMemo } from "react";
import { FlatList, ListRenderItem, RefreshControl, View } from "react-native";
import { DataList as IDataList } from "./genericListAtom";

interface DataListProps {
    data: IDataList;
    renderItem: ListRenderItem<any> | null | undefined;
    isLoading: boolean;
    refresh: () => Promise<void>;
    loadMore: () => Promise<void>;
    isLoadingMore: boolean;
    keyExtractor?: (item: any, index: number) => string;
}

export const DataList: React.FC<DataListProps> = ({
    data,
    renderItem,
    isLoading,
    refresh,
    loadMore,
    isLoadingMore,
    keyExtractor,
}) => {
    return useMemo(
        () => (
            <FlatList
                data={data.list}
                renderItem={renderItem}
                refreshing={isLoading}
                //todo change
                onRefresh={refresh}
                refreshControl={<RefreshControl refreshing={isLoading} />}
                onEndReachedThreshold={0.1}
                onEndReached={loadMore}
                keyExtractor={keyExtractor}
                ListFooterComponent={
                    isLoadingMore ? (
                        <View
                            style={{
                                height: 100,
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            {(data.total || 0) > data.page * 30 && <Spinner />}
                        </View>
                    ) : (
                        <></>
                    )
                }
            />
        ),
        [data.list, data.page, data.total, isLoading, isLoadingMore, keyExtractor, loadMore, refresh, renderItem]
    );
};
