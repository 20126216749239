import React from "react";
import { RecipeNavigator } from ".";
import { RecipeListScreen } from "../recipe/RecipeListScreen";
import { RecipeShowScreen } from "../recipe/RecipeShowScreen";

export const RecipeStack = () => {
    return (
        <RecipeNavigator.Navigator
            screenOptions={{ headerShown: false }}
            initialRouteName="RecipeListScreen"
        >
            <RecipeNavigator.Screen
                name="RecipeListScreen"
                component={RecipeListScreen}
            />
            <RecipeNavigator.Screen name="RecipeShowScreen">
                {(props) => <RecipeShowScreen {...props.route.params} />}
            </RecipeNavigator.Screen>
        </RecipeNavigator.Navigator>
    );
};
