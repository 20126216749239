import { AddFlavorDto } from "@juicy/models/dto";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { useToast } from "react-native-toast-notifications";
import { useRecoilState, useSetRecoilState } from "recoil";
import { apiService } from "../../App";
import { DataListType, useUpdateDataList } from "../common/dataList/useDataList";
import { FlavorNavigation } from "../navigation";
import {
    ManufacturerSearchStringAtom,
    ManufacturerSelectedOptionAtom
} from "./manufacturer/manufacturerAtoms";

export const useCreateFlavor = () => {
    const navigation = useNavigation<FlavorNavigation>();
    const [name, setName] = useState("");
    const [selectedManufacturerOption, setSelectedManufacturerOption] =
        useRecoilState(ManufacturerSelectedOptionAtom);
    const setSearchString = useSetRecoilState(ManufacturerSearchStringAtom);
    const [isValid, setIsValid] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const { add } = useUpdateDataList(DataListType.Flavor);

    const toast = useToast();

    useEffect(() => {
        setIsValid(!!name && !!selectedManufacturerOption);
    }, [name, selectedManufacturerOption]);

    const save = async () => {
        if (isValid && selectedManufacturerOption) {
            setIsSaving(true);
            const data: AddFlavorDto = {
                name,
                manufacturerId: +selectedManufacturerOption?.value,
            };
            const res = await apiService.flavors.create(data);
            add(res);
            setSearchString("");
            toast.show("Flavor created", {
                type: "success",
            });
            setIsSaving(false);
        }
    };

    const saveAndGoBack = async () => {
        await save();
        if (navigation.canGoBack()) {
            navigation.goBack();
        } else {
            navigation.navigate("FlavorListScreen");
        }
    };

    const saveAndCreateAnother = async () => {
        await save();
        setName("");
        setSelectedManufacturerOption(null);
    };

    return {
        saveAndCreateAnother,
        saveAndGoBack,
        isValid,
        isSaving,
        name,
        setName,
    };
};
