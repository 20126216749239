import { Recipe } from "@juicy/models";
import { useNavigation } from "@react-navigation/native";
import { Button, Card, Divider, Icon, Input, Text } from "@ui-kitten/components";
import React, { useState } from "react";
import { GestureResponderEvent, StyleSheet, TouchableOpacity, View } from "react-native";
import { apiService } from "../../App";
import { AppNavigation } from "../navigation";
import { FreeformIngredientRow } from "./freeformIngredientRow";
import { IngredientRow } from "./IngredientRow";
import { getColorForRating, Rating } from "./rating";

interface RecipeListItemProps {
    recipe: Recipe;
    disabled?: boolean;
}

export interface Flavor {
    brand: string;
    name: string;
    percentage: number;
}

interface CardHeaderProps {
    recipe: Recipe;
    disabled?: boolean;
}

interface InputButttonsProps {
    onSave: (e: GestureResponderEvent) => void;
    onCancel: (e: GestureResponderEvent) => void;
    showButtonText?: boolean;
}
export const InputButttons: React.FC<InputButttonsProps> = ({ onSave, onCancel, showButtonText, ...rest }) => (
    <View style={{ flexDirection: "row", justifyContent: "flex-end" }}>
        <Button
            {...rest}
            onPress={onSave}
            appearance="ghost"
            size={showButtonText ? "medium" : "tiny"}
            accessoryLeft={(props) => <Icon {...props} style={{ width: 22, height: 22 }} fill={"black"} name="checkmark-circle-outline" />}
        >
            {showButtonText ? "Save" : ""}
        </Button>
        <Button
            {...rest}
            onPress={onCancel}
            appearance="ghost"
            size={showButtonText ? "medium" : "tiny"}
            accessoryLeft={(props) => <Icon {...props} style={{ width: 22, height: 22 }} fill={"black"} name="close-outline" />}
        >
            {showButtonText ? "Cancel" : ""}
        </Button>
    </View>
);

const CardHeader: React.FC<CardHeaderProps> = ({ recipe, disabled, ...rest }) => {
    const [isInputShown, setIsInputShown] = useState(false);
    const [inputValue, setInputValue] = useState(recipe.name || "");

    const handleTouchTitle = (e: GestureResponderEvent) => {
        setIsInputShown(true);
        e.stopPropagation();
    };
    const handleCancel = (e: GestureResponderEvent) => {
        setIsInputShown(false);
        setInputValue(recipe.name || "");
        e.stopPropagation();
    };
    const handleSaveName = async () => {
        recipe.name = inputValue;
        console.log(recipe);
        void apiService.recipe.edit(recipe);
        setIsInputShown(false);
    };

    const handleSaveRating = async (rating?: number) => {
        recipe.rating = rating;
        console.log(recipe);
        void apiService.recipe.edit(recipe);
    };

    return (
        <View {...rest} style={styles.cardHeader}>
            <TouchableOpacity onPress={handleTouchTitle} style={{ flexDirection: "column" }}>
                {isInputShown && (
                    <View style={{ display: "flex", flexDirection: "row" }}>
                        <Input
                            onKeyPress={(e) => {
                                if (e.nativeEvent.key === "Enter") {
                                    void handleSaveName();
                                }
                            }}
                            value={inputValue}
                            onChangeText={(v) => setInputValue(v)}
                            autoFocus={true}
                            size="small"
                            placeholder="Set recipe name"
                            style={{ marginRight: 5 }}
                        />
                        <InputButttons onCancel={handleCancel} onSave={() => handleSaveName()} />
                    </View>
                )}
                {!isInputShown && (
                    <Text numberOfLines={1} ellipsizeMode="tail" category="s1">
                        {recipe.name || "[Click to set name]"}
                        {recipe.version}
                    </Text>
                )}
                <Text style={{ marginTop: 5 }} numberOfLines={1} ellipsizeMode="tail" category="c2">
                    {new Date(recipe.createdAt).toDateString()}
                </Text>
            </TouchableOpacity>
            {!isInputShown && <Rating size="large" rating={recipe.rating} onChange={handleSaveRating} disabled={disabled} />}
        </View>
    );
};

export const RecipeListItem: React.FC<RecipeListItemProps> = ({ recipe, disabled }) => {
    const navigation = useNavigation<AppNavigation>();

    return (
        <Card
            style={{
                marginBottom: 5,
                borderLeftWidth: 2,
                borderLeftColor: getColorForRating(recipe.rating),
            }}
            onPress={() =>
                navigation.navigate("RecipeStackScreen", {
                    screen: "RecipeShowScreen",
                    params: {
                        recipeId: recipe.id,
                    },
                    initial: false,
                })
            }
            header={(props) => <CardHeader {...props} recipe={recipe} disabled={disabled} />}
        >
            {recipe.freeformIngredients &&
                recipe.freeformIngredients.length > 0 &&
                recipe.freeformIngredients.map((ffIngredient) => (
                    <View key={ffIngredient.id}>
                        <FreeformIngredientRow freeformIngredient={ffIngredient} />
                        <Divider />
                    </View>
                ))}
            {recipe.ingredients &&
                recipe.ingredients.length > 0 &&
                recipe.ingredients.map((ingredient) => (
                    <View key={`${ingredient.recipeId}-${ingredient.recipeVersion}-${ingredient.flavorId}`}>
                        <IngredientRow ingredient={ingredient} />
                        <Divider />
                    </View>
                ))}
        </Card>
    );
};

const styles = StyleSheet.create({
    cardHeader: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 16,
        paddingRight: 16,
    },
    topVersionBadge: {
        backgroundColor: "#efe0ff",
        border: "1px solid #5d19a6",
        borderRadius: 4,
        paddingTop: 1,
        paddingBottom: 1,
        paddingRight: 5,
        paddingLeft: 5,
    },
    subtitle: {
        display: "flex",
        flexDirection: "row",
        marginTop: 4,
    },
});
