import { FreeformIngredient, Ingredient, Recipe } from "@juicy/models";
import { Card, Divider, Text } from "@ui-kitten/components";
import React from "react";
import { StyleSheet, View } from "react-native";
import { FreeformIngredientRow } from "./freeformIngredientRow";
import { IngredientRow } from "./IngredientRow";
import { Rating } from "./rating";

interface RecipeListItemProps {
    recipe: Recipe;
}

export interface Flavor {
    brand: string;
    name: string;
    percentage: number;
}

interface HistoryItemProps {
    currentVersion: Recipe;
    previousVersion?: Recipe;
}

const getFreeformPercentageChange = (currentFFIngredient: FreeformIngredient, prevVersion?: Recipe) => {
    if (prevVersion) {
        const prevIngredient = prevVersion.freeformIngredients.find((prev) => prev.name === currentFFIngredient.name);
        if (prevIngredient) {
            const change = currentFFIngredient.percentage - prevIngredient.percentage;
            if (change === 0) {
                return undefined;
            } else {
                return change;
            }
        }
    }
    return undefined;
};
const getPercentageChange = (currentIngredient: Ingredient, prevVersion?: Recipe) => {
    if (prevVersion) {
        const prevIngredient = prevVersion.ingredients.find((prev) => prev.flavorId === currentIngredient.flavorId);
        if (prevIngredient) {
            const change = currentIngredient.percentage - prevIngredient.percentage;
            if (change === 0) {
                return undefined;
            } else {
                return change;
            }
        }
    }
    return undefined;
};

const HistoryItem: React.FC<HistoryItemProps> = ({ currentVersion, previousVersion }) => {
    if (previousVersion && previousVersion.ingredients && currentVersion.ingredients) {
        currentVersion.ingredients.sort((a, b) => {
            const prevA = previousVersion.ingredients.findIndex((i) => i.flavorId === a.flavorId);
            // console.log(a.fullName, prevA);
            const prevB = previousVersion.ingredients.findIndex((i) => i.flavorId === b.flavorId);
            console.log(a.fullName, b.fullName, prevA, prevB, prevA - prevB ? "A wins" : "B Wins");

            return prevA - prevB;
        });
    }

    return (
        <View>
            <View style={styles.historyItemVersionContainer}>
                <View
                    style={{
                        flexDirection: "row",
                        paddingTop: 10,
                        paddingBottom: 5,
                    }}
                >
                    <Text category="c2">Version {currentVersion.version}</Text>
                    <Text appearance={"hint"} category="c1">
                        {" "}
                        @ {new Date(currentVersion.createdAt).toDateString()}
                    </Text>
                </View>
                <Rating size="small" disabled={true} rating={currentVersion.rating} />
            </View>
            <Card disabled={true}>
                {currentVersion.freeformIngredients &&
                    currentVersion.freeformIngredients.length > 0 &&
                    currentVersion.freeformIngredients.map((ffIngredient) => (
                        <View style={styles.rowContainer} key={ffIngredient.id}>
                            <FreeformIngredientRow
                                freeformIngredient={ffIngredient}
                                diffAdd={
                                    previousVersion &&
                                    !previousVersion.freeformIngredients.some((prev) => prev.name === ffIngredient.name)
                                }
                                diffPercentageChange={getFreeformPercentageChange(ffIngredient, previousVersion)}
                            />
                            <Divider />
                        </View>
                    ))}
                {(previousVersion?.freeformIngredients?.length || 0) > 0 &&
                    previousVersion!.freeformIngredients.map((prevFfIngredient) => {
                        if (!currentVersion.freeformIngredients.some((curr) => curr.name === prevFfIngredient.name)) {
                            return (
                                <View style={styles.rowContainer} key={prevFfIngredient.id}>
                                    <FreeformIngredientRow freeformIngredient={prevFfIngredient} diffRemove={true} />
                                    <Divider />
                                </View>
                            );
                        }
                    })}
                {currentVersion.ingredients.length > 0 &&
                    currentVersion.ingredients.map((ingredient) => (
                        <View
                            style={styles.rowContainer}
                            key={`${ingredient.recipeId}-${ingredient.recipeVersion}-${ingredient.flavorId}`}
                        >
                            <IngredientRow
                                ingredient={ingredient}
                                diffAdd={
                                    previousVersion &&
                                    !previousVersion.ingredients.some((prev) => prev.flavorId === ingredient.flavorId)
                                }
                                diffPercentageChange={getPercentageChange(ingredient, previousVersion)}
                            />
                            <Divider />
                        </View>
                    ))}
                {previousVersion?.ingredients &&
                    previousVersion.ingredients.length > 0 &&
                    previousVersion.ingredients.map((prevIngredient) => {
                        if (!currentVersion.ingredients.some((curr) => curr.flavorId === prevIngredient.flavorId)) {
                            return (
                                <View
                                    style={styles.rowContainer}
                                    key={`${prevIngredient.recipeId}-${prevIngredient.recipeVersion}-${prevIngredient.flavorId}`}
                                >
                                    <IngredientRow ingredient={prevIngredient} diffRemove={true} />
                                    <Divider />
                                </View>
                            );
                        }
                    })}
            </Card>
        </View>
    );
};

export const RecipeHistory: React.FC<RecipeListItemProps> = ({ recipe }) => {
    return (
        <View>
            <Text style={{ marginTop: 10 }} category="h6">
                History
            </Text>
            {recipe.history &&
                recipe.history.map((historyItem, index) => (
                    <HistoryItem
                        key={`history-item-${historyItem.version}`}
                        currentVersion={historyItem}
                        previousVersion={recipe.history[index - 1]}
                    />
                ))}
        </View>
    );
};

const styles = StyleSheet.create({
    historyItemVersionContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    rowContainer: {
        paddingLeft: 16,
        paddingRight: 16,
    },
});
