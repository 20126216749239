import { Card, Input, Text } from "@ui-kitten/components";
import React, { useState } from "react";
import { View } from "react-native";
import { InputButttons } from "./RecipeListItem";

interface DescriptionProps {
    description?: string;
    onChange: (newDescription: string) => void;
}

export const Description: React.FC<DescriptionProps> = ({ description, onChange }) => {
    const [shouldShowInput, setShouldShowInput] = useState(false);
    const [internalDescription, setInternalDescription] = useState(description || "");

    return (
        <View>
            <Text style={{ marginTop: 10, marginBottom: 10 }} category="h6">Description</Text>
            <Card onPress={() => setShouldShowInput(true)}>
                {!shouldShowInput && (
                    <Text
                        appearance={internalDescription ? "default" : "hint"}
                        category={internalDescription ? "p1" : "s2"}
                    >
                        {internalDescription || "Click to set description"}
                    </Text>
                )}
                {shouldShowInput && (
                    <View style={{padding: 0}}>
                        <Input
                            autoFocus={true}
                            style={{ marginTop: 10, marginBottom: 10 }}
                            value={internalDescription}
                            onChangeText={setInternalDescription}
                            multiline={true}
                            textStyle={{ minHeight: 100 }}
                        />
                        <InputButttons
                            onSave={() => {
                                onChange(internalDescription);
                                setShouldShowInput(false);
                            }}
                            onCancel={() => { setShouldShowInput(false); }}
                            showButtonText={true}
                        />
                    </View>
                )}
            </Card>
        </View>
    );
};