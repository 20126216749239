import { Autocomplete, AutocompleteItem, Button, Icon, Input } from "@ui-kitten/components";
import React from "react";

export interface Option {
    value: string;
    label: string;
    selected: boolean;
}

interface CreatableAutocompleteAsyncProps {
    value: string;
    options: Option[];
    isLoading: boolean;
    onChangeInput: (text: string) => void;
    onSelect: (index: number) => void;
    placeholder?: string;
    label?: string;
    selectedOption?: Option;
    onFocus?: () => void;
    onClear?: () => void;
}

export const CreatableAutocompleteAsync: React.FC<CreatableAutocompleteAsyncProps> = ({
    value,
    options,
    isLoading,
    onChangeInput,
    selectedOption,
    onSelect,
    onFocus,
    label,
    onClear,
}) => {
    const renderOption = (item: Option, index: number) => <AutocompleteItem key={index} title={item.label} />;

    if (selectedOption) {
        return (
            <Input
                onFocus={onFocus}
                value={selectedOption.label}
                accessoryRight={(props) => (
                    <Button
                        size="tiny"
                        appearance="ghost"
                        {...props}
                        accessoryRight={(props) => <Icon {...props} name="close" />}
                        onPress={onClear}
                    />
                )}
            />
        );
    }
    return (
        <Autocomplete
            placeholder="Start typing..."
            onChangeText={onChangeInput}
            value={value}
            onSelect={onSelect}
            label={label}
        >
            {options.map(renderOption)}
        </Autocomplete>
    );
};
