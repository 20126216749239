import { FreeformIngredientDto } from "@juicy/models/dto";
import { Icon, Text } from "@ui-kitten/components";
import React from "react";
import { StyleSheet, View } from "react-native";

interface FlavorRowProps {
    freeformIngredient: FreeformIngredientDto;
    diffAdd?: boolean;
    diffRemove?: boolean;
    diffPercentageChange?: number;
}

export const FreeformIngredientRow: React.FC<FlavorRowProps> = ({ freeformIngredient, diffAdd, diffRemove, diffPercentageChange }) => {
    return (
        <View style={[styles.container, diffAdd && styles.addedRow, diffRemove && styles.removedRow]}>
            <View style={styles.leftColumn}>
                {diffAdd && (
                    <Icon
                        name="plus"
                        fill={"green"}
                        style={styles.diffIcon}
                    />
                )}
                {diffRemove && (
                    <Icon
                        name="minus"
                        fill={"red"}
                        style={styles.diffIcon}
                    />
                )}
                <Text style={[
                    styles.nameText,
                    diffRemove && {
                        // textDecorationLine: "line-through",
                        color: "#b00000"
                    }]
                }>{freeformIngredient.name}</Text>
            </View>
            <View style={styles.rightColumn}>
                <Text style={diffRemove && {
                    // textDecorationLine: "line-through",
                    color: "#b00000"
                }}>{freeformIngredient.percentage}%</Text>
                {diffPercentageChange && (
                    <Text
                        category="c2"
                        appearance={"alternative"}
                        style={[
                                styles.diffTextRight, {
                                color: diffPercentageChange > 0 ? "green" : "#cc0202"
                            }
                        ]}>
                        ({diffPercentageChange > 0 ? "+" : ""}{diffPercentageChange})
                    </Text>
                )}
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        height: 36,
        paddingLeft: 5,
        paddingRight: 5
    },
    leftColumn: {
        flex: 4,
        flexDirection: "row",
        alignItems: "center"
    },
    rightColumn: {
        flexDirection: "row",
        width: 60,
        justifyContent: "flex-end"
    },
    brandText: {
        flex: 1
    },
    nameText: {
        flex: 4
    },
    diffIcon: {
        width: 26,
        height: 26,
        position: "absolute",
        left: -36,
        top: -12
    },
    addedRow: {
        backgroundColor: "#ebfff1"
    },
    removedRow: {
        backgroundColor: "#ffeded",
    },
    diffTextRight: {
        position: "absolute",
        right: -36,
        top: 1,
        textAlign: "left"
    }
});
