import React, { useRef, useState } from "react";
import { Slider } from "@miblanchard/react-native-slider";
import { TouchableOpacity, View, StyleSheet } from "react-native";
import { Card, Text, useTheme } from "@ui-kitten/components";

interface MixerSliderProps {
    value: number;
    onValueChange: (value: number) => void;
    max: number;
    min?: number;
    unit?: string;
}

const borderRadius = 12;

const ToolTip = ({ value }: { value: number }) => {
    return (
        <Card
            style={{
                width: 64,
                height: 64,
                zIndex: 99,
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Text category="h1" style={{ fontWeight: "100" }}>
                {value}
            </Text>
        </Card>
    );
};

const ThumbComponent = ({ tintColor = "green" }) => {
    return (
        <TouchableOpacity>
            <View
                style={{
                    width: 36,
                    height: 36,
                    backgroundColor: tintColor,
                    borderRadius,
                    zIndex: 999,
                }}
            />
        </TouchableOpacity>
    );
};

export const MixerSlider: React.FC<MixerSliderProps> = ({ value, onValueChange, min = 0, max, unit = "%" }) => {
    const theme = useTheme();
    // const tintColor = theme["color-success-500"];
    const tintColor = theme["color-primary-300"];
    const [isSliding, setIsSliding] = useState(false);
    const timer = useRef<NodeJS.Timeout | null>(null);

    const handleChange = (value: number | number[]) => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
            onValueChange(Math.round(Math.floor(value as number)));
        }, 1);
    };

    const renderTooltip = (): React.ReactNode => {
        if (isSliding) {
            return <ToolTip value={value} />;
        }
        return <></>;
    };

    return (
        <View>
            <View
                pointerEvents="none"
                style={[
                    StyleSheet.absoluteFill,
                    {
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 99,
                    },
                ]}
            >
                <Text style={{ color: "#fff", fontSize: 24 }}>
                    {value}
                    {unit}
                </Text>
            </View>
            <Slider
                animateTransitions
                value={value}
                onValueChange={handleChange}
                renderThumbComponent={() => <ThumbComponent tintColor={tintColor} />}
                trackStyle={styles.track}
                minimumTrackTintColor={tintColor}
                maximumTrackTintColor={"#333"}
                renderAboveThumbComponent={() => renderTooltip()}
                maximumValue={max}
                minimumValue={min}
                onSlidingComplete={() => setIsSliding(false)}
                onSlidingStart={() => setIsSliding(true)}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    track: {
        height: 36,
        borderRadius,
    },
});
