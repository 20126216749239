import { Button, Divider, Icon, Input } from "@ui-kitten/components";
import React from "react";
import { View } from "react-native";
import { Option } from "../../common/autocomplete";
import { MixerFlavorCreatableAutocomplete } from "./MixerFlavorCreatableAutocomplete";

export interface MixerIngredientFormValues {
    _id: string;
    percentage: number;
    flavor?: Option;
}

interface MixerIngredientFormProps {
    values: MixerIngredientFormValues;
    onChange: (values: MixerIngredientFormValues) => void;
    onRemove: () => void;
}

export const MixerIngredientForm: React.FC<MixerIngredientFormProps> = ({ values, onChange, onRemove }) => {
    const handleChange = (key: keyof MixerIngredientFormValues, value: any) => {
        onChange({
            ...values,
            [key]: value,
        });
    };

    return (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
            <View style={{ flex: 1, marginRight: 16 }}>
                <MixerFlavorCreatableAutocomplete
                    onSelectFlavor={(flavor) => handleChange("flavor", flavor)}
                    selectedFlavorOption={values.flavor}
                />
            </View>
            <View style={{ flex: 1 }}>
                <Input
                    value={values.percentage.toString()}
                    onChangeText={(text) => handleChange("percentage", +text)}
                    style={{ flex: 1 }}
                />
            </View>
            <View style={{ width: 56 }}>
                <Button
                    onPress={onRemove}
                    appearance="ghost"
                    status="basic"
                    accessoryLeft={(props) => <Icon name="trash-outline" {...props} />}
                />
            </View>
            <Divider />
        </View>
    );
};
