import React, { Suspense } from "react";
import { View } from "react-native";
import { LoginButton } from "./LoginButton";

export const HeaderRight = () => {
    return (
        <View>
            <Suspense fallback={<></>} >
                <LoginButton />
            </Suspense>
        </View>
    );
};
