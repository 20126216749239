import { Recipe } from "@juicy/models";
import { Spinner } from "@ui-kitten/components";
import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { apiService } from "../../App";
import { Container } from "../common/layout/Boxes";
import { ScreenLayout } from "../common/layout/ScreenLayout";
import { Description } from "./description";
import { RecipeHistory } from "./history";
import { RecipeListItem } from "./RecipeListItem";

interface RecipeShowScreenProps {
    recipeId: number;
}

export const RecipeShowScreen: React.FC<RecipeShowScreenProps> = ({ recipeId }) => {
    const [recipe, setRecipe] = useState<Recipe | null>(null);
    console.log({ recipe });

    const handleSaveDescription = (description: string) => {
        if (recipe) {
            recipe.description = description;
            void apiService.recipe.edit(recipe);
        }
    };

    useEffect(() => {
        apiService.recipe
            .getById(recipeId)
            .then((data) => {
                setRecipe(data);
            })
            .catch(() => {
                /* */
            });
    }, [recipeId]);

    return (
        <ScreenLayout scroll={true}>
            <Container>
                {!recipe ? (
                    <View>
                        <Spinner />
                    </View>
                ) : (
                    <View>
                        <RecipeListItem recipe={recipe} />
                        <Description description={recipe.description} onChange={handleSaveDescription} />
                        {recipe && recipe.history && <RecipeHistory recipe={recipe} />}
                    </View>
                )}
            </Container>
        </ScreenLayout>
    );
};
