import React from "react";
import { RootStackNavigator } from ".";
import { BottomTabStack } from "./BottomTabStack";
import { HeaderRight } from "./HeaderRight";
import { RecipeStack } from "./RecipeStack";

export const RootStack = () => {
    const isLogged = true;
    return (
        <RootStackNavigator.Navigator
            screenOptions={{
                title: "Juicy",
                headerTransparent: true,
                headerRight: () => <HeaderRight />,
            }}
        >
            {!isLogged ? (
                <RootStackNavigator.Screen name="RecipeStackScreen" component={RecipeStack} />
            ) : (
                <RootStackNavigator.Screen
                    name="BottomTabStackScreen"
                    component={BottomTabStack}
                />
            )}
        </RootStackNavigator.Navigator>
    );
};
