import * as eva from "@eva-design/eva";
import { LinkingOptions, NavigationContainer } from "@react-navigation/native";
import {
    ApplicationProvider,
    IconRegistry,
    Layout,
} from "@ui-kitten/components";
import { EvaIconsPack } from "@ui-kitten/eva-icons";
import * as Linking from "expo-linking";
import React from "react";
import { Text } from "react-native";
import { HttpClient } from "./src/HttpClient";
import { RootStack } from "./src/navigation/RootStack";
import { RecoilRoot } from "recoil";
import { ApiService } from "./src/apiService";
import { RootStackParamList } from "./src/navigation";
import { ToastProvider } from "react-native-toast-notifications";
import Toast from "react-native-toast-notifications";

const prefix = Linking.createURL("/");
export const httpClient = new HttpClient((message) =>
    // @ts-ignore
    toast.show(message)
);
export const apiService = new ApiService();

const linking: LinkingOptions<RootStackParamList> = {
    prefixes: [prefix, "http://localhost:19006"],
    config: {
        screens: {
            BottomTabStackScreen: {
                path: "",
                screens: {
                    RecipeStackScreen: {
                        path: "/recipes",
                        screens: {
                            RecipeListScreen: "",
                            RecipeShowScreen: ":recipeId",
                        },
                    },
                    BatchStackScreen: {
                        path: "batches",
                        screens: {
                            BatchListScreen: "",
                            BatchCreateScreen: "create",
                        },
                    },
                    FlavorStackScreen: {
                        path: "/flavors",
                        screens: {
                            FlavorListScreen: "",
                            FlavorShowScreen: ":flavorId",
                            FlavorEditScreen: "/edit/:flavorId",
                            FlavorCreateScreen: "create",
                        },
                    },
                    ManufacturerStackScreen: {
                        path: "/manufacturers",
                        screens: {
                            ManufacturerListScreen: ""
                        }
                    }
                },
            },
        },
    },
};

export default function App() {
    return (
        <ToastProvider>
            <RecoilRoot>
                <IconRegistry icons={EvaIconsPack} />
                <ApplicationProvider {...eva} theme={eva.light}>
                    <Layout level="4" style={{ flex: 1 }}>
                        <NavigationContainer
                            linking={linking}
                            fallback={<Text>Loading...</Text>}
                        >
                            <RootStack />
                        </NavigationContainer>
                    </Layout>
                    <Toast ref={(ref) => {
                        // @ts-ignore
                        global["toast"] = ref;
                    }} />
                </ApplicationProvider>
            </RecoilRoot>
        </ToastProvider>
    );
}
