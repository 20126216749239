import { Layout, Toggle } from "@ui-kitten/components";
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import Modal from "react-native-modal";
import { SettingItem } from "../settings/SettingItem";
import { IUser, UserProfile } from "../user/UserProfile";

interface UserModalProps {
    isVisible: boolean;
    user: IUser;
    onClose: () => void;
    onPressLogout: () => void;
}

export const UserModal: React.FC<UserModalProps> = ({
    isVisible,
    onClose,
    onPressLogout,
    user,
}) => {
    const [darkModeEnabled, setDarkModeEnabled] = useState(false);

    const toggleDarkMode = () => {
        setDarkModeEnabled(!darkModeEnabled);
    };

    const onPressLogoutButton = () => {
        onPressLogout();
        onClose();
    };

    return (
        <Modal
            isVisible={isVisible}
            style={styles.modal}
            onBackdropPress={onClose}
        >
            <Layout level="4" style={[styles.container, styles.content]}>
                <UserProfile user={user} />
                <View style={{ marginBottom: 8 }} />
                <Layout
                    style={[
                        styles.container,
                        {
                            borderBottomLeftRadius: 4,
                            borderBottomRightRadius: 4,
                        },
                    ]}
                >
                    <SettingItem style={styles.setting} hint="Edit Profile" />
                    <SettingItem style={styles.setting} hint="Privacy" />
                    <SettingItem
                        style={[styles.setting, styles.section]}
                        hint="Dark Mode"
                        onPress={toggleDarkMode}
                    >
                        <Toggle
                            checked={darkModeEnabled}
                            onChange={toggleDarkMode}
                        />
                    </SettingItem>
                    <SettingItem
                        onPress={onPressLogoutButton}
                        style={styles.setting}
                        hint="Logout"
                    />
                </Layout>
            </Layout>
        </Modal>
    );
};

const styles = StyleSheet.create({
    modal: {
        width: "100%",
        alignItems: "center",
    },
    content: {
        borderRadius: 4,
    },
    container: {
        flex: 1,
        width: 350,
        marginHorizontal: -16,
        paddingHorizontal: 16,
    },
    setting: {
        padding: 16,
    },
    section: {
        paddingTop: 32,
    },
});
