import { ManufacturerDto } from "@juicy/models/src/dto/addManufacturerDto";
import { Card, Text } from "@ui-kitten/components";
import React from "react";
import { StyleSheet, View } from "react-native";

interface ManufacturerListItemProps {
    manufacturer: ManufacturerDto;
}

export const ManufacturerListItem: React.FC<ManufacturerListItemProps> = ({ manufacturer }) => {

    return (
        <Card
            onPress={() => console.log("card pressed")}
            style={{
                marginBottom: 5,
                borderLeftWidth: 2,
            }}
        // onPress={() => navigation.navigate("RecipeStackScreen", {
        //     screen: "RecipeShowScreen",
        //     params: {
        //         recipeId: recipe.id
        //     },
        //     initial: false
        // })}
        >
            <View style={styles.textContainer}>
                <Text category="s1">{manufacturer.shortname}</Text><Text category="s2">{manufacturer.longname}</Text>
            </View>

        </Card>
    );
};

const styles = StyleSheet.create({
    textContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },
});
