import { FlavorDto } from "@juicy/models/dto";
import { Card, Text } from "@ui-kitten/components";
import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { apiService } from "../../App";
import { getColorForRating, Rating} from "../recipe/rating";

interface FlavorListItemProps {
    flavor: FlavorDto;
    onPress: () => void;
}

export const FlavorListItem: React.FC<FlavorListItemProps> = ({
    flavor,
    onPress,
}) => {
    const [internalFlavor, setInternalFlavor] = useState<FlavorDto>(flavor);

    const handleSaveRating = async (rating?: number) => {
        const newFlavor = { ...flavor, rating };
        await apiService.flavors.edit(newFlavor);
        setInternalFlavor(newFlavor);
    };

    useEffect(() => {
        setInternalFlavor(flavor);
    }, [flavor]);

    return (
        <Card
            style={{
                borderLeftWidth: 2,
                borderLeftColor: getColorForRating(internalFlavor.rating),
            }}
            onPress={onPress}
        >
            <View style={styles.root}>
                <View style={{ flexDirection: "column" }}>
                    <Text numberOfLines={1} ellipsizeMode="tail" category="s1">
                        {internalFlavor.fullName || internalFlavor.name}
                    </Text>
                    <Text
                        style={{ marginTop: 5 }}
                        numberOfLines={1}
                        ellipsizeMode="tail"
                        category="c2"
                    >
                        {new Date(internalFlavor.createdAt).toDateString()}
                    </Text>
                </View>
                <Rating
                    size="large"
                    rating={internalFlavor.rating}
                    onChange={handleSaveRating}
                    disabled={false}
                />
            </View>
        </Card>
    );
};

const styles = StyleSheet.create({
    root: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },
});
