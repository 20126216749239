import { useNavigation } from "@react-navigation/native";
import { Button, Icon, Input, Text } from "@ui-kitten/components";
import React, { useState } from "react";
import { View } from "react-native";
import { DataList } from "../common/dataList/DataList";
import { DataListType, useDataList } from "../common/dataList/useDataList";
import { ScreenLayout } from "../common/layout/ScreenLayout";
import { FlavorNavigation } from "../navigation";
import { FlavorFilters } from "./flavorFilters";
import { FlavorListItem } from "./FlavorListItem";

export const FlavorListScreen: React.FC = () => {
    const { data, isLoading, setFilter, filter, refresh, loadMore, isLoadingMore } = useDataList(DataListType.Flavor);
    const navigation = useNavigation<FlavorNavigation>();
    const [showFilters, setShowFilters] = useState(false);

    return (
        <ScreenLayout scroll={false} title="Recipes">
            <View style={{ flex: 1, padding: 8 }}>
                <View
                    style={{
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        height: 56,
                        alignItems: "center",
                    }}
                >
                    <Text category="h4" style={{ fontWeight: "600" }}>
                        FLAVORS
                    </Text>
                    <Button
                        onPress={refresh}
                        appearance="ghost"
                        status="basic"
                        accessoryLeft={(props) => <Icon {...props} name="refresh" />}
                    />
                </View>
                <View
                    style={{
                        flexDirection: "row",
                        alignItems: "center",
                        height: 50,
                    }}
                >
                    <Input
                        placeholder="Search flavors"
                        onChangeText={(text) => {
                            setFilter((current) => ({
                                ...current,
                                search: text,
                            }));
                        }}
                        value={filter.search}
                        style={{ flex: 1 }}
                    />
                    <Button
                        status="primary"
                        appearance="outline"
                        onPress={() => setShowFilters((prev) => !prev)}
                        style={{ width: 32, marginLeft: 16 }}
                        size="small"
                        accessoryRight={(props) => <Icon {...props} name="funnel-outline" />}
                    />
                    <Button
                        onPress={() => navigation.navigate("FlavorCreateScreen")}
                        style={{ width: 32, marginLeft: 16 }}
                        size="small"
                        accessoryRight={(props) => <Icon {...props} name="plus" />}
                    />
                </View>
                {showFilters && <FlavorFilters />}
                <DataList
                    data={data}
                    renderItem={({ item }) => {
                        return (
                            <View style={{ paddingVertical: 4 }}>
                                <FlavorListItem
                                    flavor={item}
                                    onPress={() =>
                                        navigation.navigate("FlavorEditScreen", {
                                            flavorId: item.id,
                                        })
                                    }
                                />
                            </View>
                        );
                    }}
                    isLoading={isLoading}
                    isLoadingMore={isLoadingMore}
                    loadMore={loadMore}
                    refresh={refresh}
                    keyExtractor={(item, index) => `flavor-${item.manufacturerId}-${item.id}-${index}`}
                />
            </View>
        </ScreenLayout>
    );
};
