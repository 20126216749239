import { atom, selector } from "recoil";
import AsyncStorage from "@react-native-async-storage/async-storage";

export interface UserState {
    username: string;
    picture: string | null;
}

const getUserStateFromStorage = async (): Promise<UserState | null> => {
    const IdToken = await AsyncStorage.getItem("IdToken");
    if (IdToken) {
        const username = await AsyncStorage.getItem("username") || "";
        const picture = await AsyncStorage.getItem("picture");
        return {
            username,
            picture,
        };
    } else {
        return null;
    }
};

const asyncStorageSelector = selector({
    key: "asyncStorageSelector",
    get: async () => {
        return await getUserStateFromStorage();
    }
});

export const userStateAtom = atom<UserState | null>({
    key: "userState",
    default: asyncStorageSelector
});
