import { Icon, Text } from "@ui-kitten/components";
import React, { useRef, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import StarRating from "react-native-star-rating-widget";

interface RatingProps {
    rating?: number;
    onChange?: (newRating?: number) => void,
    disabled?: boolean;
    size: "large" | "small";
}

export const getColorForRating = (rating?: number) => {
    if (!rating) {
        return "#999";
    } else if (rating < 5) {
        return "#913101";
    } else if (rating >= 5 && rating < 7) {
        return "#c4c108";
    } else {
        return "green";
    }
};

interface RatingTextProps {
    size: "large" | "small";
    rating?: number;
}
const RatingText: React.FC<RatingTextProps> = ({ size, rating }) => (
    <View style={[styles.ratingTextContainer, {}]}>
        <Text style={{ fontSize: size === "large" ? 20 : 16, fontWeight: "bold" }}>{rating || ""}</Text>
        {rating && <Text style={{ fontSize: size === "large" ? 16 : 12 }}> / 10</Text>}
    </View>
);

export const Rating: React.FC<RatingProps> = ({ rating, onChange, disabled, size }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const timerRef = useRef<NodeJS.Timeout | null>(null);
    const [internalRating, setInternalRating] = useState(rating);

    return (
        <View style={styles.root}>
            {isExpanded ? (
                <View style={styles.container}>
                    <StarRating
                        style={{ marginTop: 3 }}
                        rating={internalRating ? (internalRating / 2) : 0}
                        onChange={(x) => {
                            setInternalRating(x === 0 ? undefined : x * 2);
                            if (onChange) {
                                if (timerRef.current !== null) {
                                    clearTimeout(timerRef.current);
                                }
                                timerRef.current = setTimeout(() => {
                                    onChange(x === 0 ? undefined : x * 2);
                                    setIsExpanded(false);
                                }, 1000);
                            }
                        }}
                        maxStars={5}
                        minRating={2}
                        starSize={25}
                        enableHalfStar={true}
                        color={getColorForRating(internalRating)}
                        starStyle={{ marginRight: 0, marginLeft: 0 }}
                    />
                    <RatingText size={size} rating={internalRating} />
                </View>
            ) : (
                <TouchableOpacity
                    disabled={disabled}
                    style={styles.container}
                    onPress={(e) => {
                        setIsExpanded(true);
                        if (timerRef.current !== null) {
                            clearTimeout(timerRef.current);
                        }
                        timerRef.current = setTimeout(() => {
                            setIsExpanded(false);
                        }, 4000);

                        e.stopPropagation();
                        }}
                    >
                    <Icon
                        name="star"
                        fill={getColorForRating(internalRating)}
                        style={{
                            width: size === "large" ? 22 : 18,
                            height: size === "large" ? 22 : 18,
                            marginRight: size === "large" ? -5 : -5
                        }}
                    />
                    <RatingText size={size} rating={internalRating} />
                </TouchableOpacity>
            )}
        </View>
    );
};

const styles = StyleSheet.create({
    root: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",

    },
    container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "flex-end"
    },
    ratingTextContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        marginLeft: 10
    }
});