import { atom } from "recoil";
import { Option } from "../../common/autocomplete";

export const ManufacturerModalVisibleAtom = atom<boolean>({
    key: "ManufacturerModalVisibleAtom",
    default: false,
});

export const ManufacturerIsSavingAtom = atom<boolean>({
    key: "ManufacturerIsSavingAtom",
    default: false,
});

export const ManufacturerSelectedOptionAtom = atom<Option | null>({
    key: "ManufacturerSelectedOptionAtom",
    default: null,
});

export const ManufacturerOptionsAtom = atom<Option[]>({
    key: "ManufacturerOptionsAtom",
    default: [],
});

export const ManufacturerIsLoadingAtom = atom<boolean>({
    key: "ManufacturerIsLoadingAtom",
    default: false,
});

export const ManufacturerSearchStringAtom = atom<string>({
    key: "ManufacturerSearchStringAtom",
    default: "",
});
