import React from "react";
import { BatchNavigator } from ".";
import { BatchCreateScreen } from "../batch/BatchCreateScreen";
import { BatchListScreen } from "../batch/BatchListScreen";

export const BatchStack = () => {
    return (
        <BatchNavigator.Navigator screenOptions={{ headerShown: false }} initialRouteName="BatchListScreen">
            <BatchNavigator.Screen name="BatchListScreen" component={BatchListScreen} />
            <BatchNavigator.Screen name="BatchCreateScreen" component={BatchCreateScreen} />
        </BatchNavigator.Navigator>
    );
};
