import { Divider, Text } from "@ui-kitten/components";
import React from "react";
import { StyleSheet, View } from "react-native";
import { MixerResult } from "../../mixer/useMixer";

interface TableProps {
    result: MixerResult;
}

const HeaderCell = ({ children }: { children: string | string[] }) => (
    <View style={styles.headerCell}>
        <Text category="p1" appearance="hint">
            {children}
        </Text>
    </View>
);

const Tr = ({ children }: { children: React.ReactNode }) => <View style={styles.tr}>{children}</View>;

const RowHeader = ({ children }: { children: string | string[] }) => (
    <View style={styles.rowHeader}>
        <HeaderCell>{children}</HeaderCell>
    </View>
);

const Cell = ({ children }: { children: string | string[] | number }) => (
    <View style={styles.cell}>
        <Text>{children}</Text>
    </View>
);

const TBody = ({ children }: { children: React.ReactNode }) => <View style={styles.tbody}>{children}</View>;

export const Table: React.FC<TableProps> = ({ result }) => {
    return (
        <View style={styles.container}>
            <View style={styles.tableHeaderHorizontal}>
                <HeaderCell>Add</HeaderCell>
                <HeaderCell>ml</HeaderCell>
                <HeaderCell>g</HeaderCell>
            </View>
            <Divider />
            <TBody>
                <Tr>
                    <RowHeader>Nic Base</RowHeader>
                    <Cell>{result.ml.nicBase.toFixed(2)}</Cell>
                    <Cell>{result.g.nicBase.toFixed(2)}</Cell>
                </Tr>
                <Divider />
                <Tr>
                    <RowHeader>VG</RowHeader>
                    <Cell>{result.ml.vg.toFixed(2)}</Cell>
                    <Cell>{result.g.vg.toFixed(2)}</Cell>
                </Tr>
                <Divider />
                <Tr>
                    <RowHeader>PG</RowHeader>
                    <Cell>{result.ml.pg.toFixed(2)}</Cell>
                    <Cell>{result.g.pg.toFixed(2)}</Cell>
                </Tr>
                <Divider />
                <Tr>
                    <RowHeader>SUM</RowHeader>
                    <Cell>{result.ml.sum}</Cell>
                    <Cell>{result.g.sum}</Cell>
                </Tr>
            </TBody>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    tableHeaderHorizontal: {
        flexDirection: "row",
        marginBottom: 16,
    },
    headerCell: {
        alignItems: "flex-start",
        flex: 1,
        margin: 1,
    },
    tr: {
        flexDirection: "row",
        height: 44,
        alignItems: "center",
    },
    rowHeader: {
        flex: 1,
    },
    cell: {
        flex: 1,
    },
    tbody: {},
});
