import {
    createMaterialBottomTabNavigator,
    MaterialBottomTabNavigationProp,
} from "@react-navigation/material-bottom-tabs";
import { NavigatorScreenParams } from "@react-navigation/native";
import {
    createNativeStackNavigator,
    NativeStackNavigationProp,
} from "@react-navigation/native-stack";

export type RootStackParamList = {
    RecipeStackScreen: NavigatorScreenParams<RecipeStackParamList>;
    BottomTabStackScreen: NavigatorScreenParams<BottomTabsStackParamList>;
};

export type RecipeStackParamList = {
    RecipeListScreen: undefined;
    RecipeShowScreen: { recipeId: number };
};

export type FlavorStackParamList = {
    FlavorListScreen: undefined;
    FlavorShowScreen: { flavorId: number };
    FlavorCreateScreen: undefined;
    FlavorEditScreen: { flavorId: number };
};

export type BatchStackParamList = {
    BatchCreateScreen: undefined;
    BatchListScreen: undefined;
};

export type ManufacturerStackParamList = {
    ManufacturerListScreen: undefined;
};

export type BottomTabsStackParamList = {
    RecipeStackScreen: NavigatorScreenParams<RecipeStackParamList>;
    BatchStackScreen: NavigatorScreenParams<BatchStackParamList>;
    FlavorStackScreen: NavigatorScreenParams<FlavorStackParamList>;
    ManufacturerStackScreen: NavigatorScreenParams<ManufacturerStackParamList>;
};

export type AppNavigation = NativeStackNavigationProp<RootStackParamList>;
export type RecipeNavigation = NativeStackNavigationProp<RecipeStackParamList>;
export type BatchNavigation = NativeStackNavigationProp<BatchStackParamList>;
export type BottomTabNavigation =
    MaterialBottomTabNavigationProp<BottomTabsStackParamList>;
export type FlavorNavigation = NativeStackNavigationProp<FlavorStackParamList>;

export const BottomTabNavigator =
    createMaterialBottomTabNavigator<BottomTabsStackParamList>();

export const RecipeNavigator =
    createNativeStackNavigator<RecipeStackParamList>();
export const BatchNavigator = createNativeStackNavigator<BatchStackParamList>();
export const FlavorNavigator =
    createNativeStackNavigator<FlavorStackParamList>();
export const ManufacturerNavigator =
    createNativeStackNavigator<ManufacturerStackParamList>();

export const RootStackNavigator =
    createNativeStackNavigator<RootStackParamList>();
