import { Button, Icon, Text } from "@ui-kitten/components";
import React from "react";
import { View } from "react-native";
import { DataList } from "../common/dataList/DataList";
import { DataListType, useDataList } from "../common/dataList/useDataList";
import { ScreenLayout } from "../common/layout/ScreenLayout";
import { ManufacturerListItem } from "./ManufacturerListItem";

export const ManufacturerListScreen: React.FC = () => {
    const { data, isLoading, refresh, loadMore, isLoadingMore } = useDataList(DataListType.Manufacturer);
    // const navigation = useNavigation<FlavorNavigation>();

    return (
        <ScreenLayout scroll={false} title="Batches">
            <View style={{ flex: 1, padding: 8 }}>
                <View
                    style={{
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        height: 56,
                        alignItems: "center",
                    }}
                >
                    <Text category="h4" style={{ fontWeight: "600" }}>
                        MANUFACTURERS
                    </Text>
                    <Button
                        onPress={refresh}
                        appearance="ghost"
                        status="basic"
                        accessoryLeft={(props) => <Icon {...props} name="refresh" />}
                    />
                </View>
                {/* <View
                    style={{
                        flexDirection: "row",
                        alignItems: "center",
                        height: 50,
                    }}
                >
                    <Input
                        placeholder="Search flavors"
                        onChangeText={(text) => {
                            onFilterChange((current) => ({
                                ...current,
                                search: text
                            }));
                        }}
                        value={filter.search}
                        style={{ flex: 1 }}
                    />
                    <Button
                        status="primary"
                        appearance="outline"
                        onPress={() =>
                            setShowFilters(prev => !prev)
                        }
                        style={{ width: 32, marginLeft: 16 }}
                        size="small"
                        accessoryRight={(props) => (
                            <Icon {...props} name="funnel-outline" />
                        )}
                    />
                    <Button
                        onPress={() =>
                            navigation.navigate("FlavorCreateScreen")
                        }
                        style={{ width: 32, marginLeft: 16 }}
                        size="small"
                        accessoryRight={(props) => (
                            <Icon {...props} name="plus" />
                        )}
                    />
                </View> */}
                {/* {showFilters && <FlavorFilters />} */}
                <DataList
                    data={data}
                    renderItem={({ item }) => (
                        <View style={{ paddingVertical: 4 }}>
                            <ManufacturerListItem manufacturer={item} />
                        </View>
                    )}
                    isLoading={isLoading}
                    isLoadingMore={isLoadingMore}
                    loadMore={loadMore}
                    refresh={refresh}
                />
            </View>
        </ScreenLayout>
    );
};
