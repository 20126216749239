import { Card, Divider, Input } from "@ui-kitten/components";
import React from "react";
import { ManufacturerForm } from "./manufacturer/ManufacturerForm";

interface FlavorFormProps {
    onNameChange: (name: string) => void;
    name: string;
}

export const FlavorForm = ({ onNameChange, name }: FlavorFormProps) => {
    return (
        <Card status="primary" style={{ marginTop: 16 }}>
            <ManufacturerForm />
            <Divider style={{ marginVertical: 16 }} />
            <Input
                label="Name"
                placeholder="Give your flavor a name"
                value={name}
                onChangeText={onNameChange}
                style={{ marginBottom: 16 }}
            />
        </Card>
    );
};
