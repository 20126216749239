import Axios, { AxiosRequestConfig, AxiosResponse } from "Axios";
import Constants from "expo-constants";
import AsyncStorage from "@react-native-async-storage/async-storage";

export class HttpClient {
    constructor(private errorHandler?: (message: string) => void) {
        Axios.defaults.baseURL = Constants.expoConfig?.extra?.apiUrl || "http://localhost:3001/api";
    }

    private async handle401() {
        const RefreshToken = await AsyncStorage.getItem("RefreshToken");
        if (RefreshToken) {
            try {
                const result = await Axios.post(`/refresh`, {
                    RefreshToken,
                });
                Axios.defaults.headers.common = {
                    Authorization: `Bearer ${result.data["id_token"]}`,
                };
                await AsyncStorage.setItem("IdToken", result.data["id_token"]);
                await AsyncStorage.setItem("RefreshToken", result.data["refresh_token"]);
            } catch (e) {
                await AsyncStorage.removeItem("IdToken");
                await AsyncStorage.removeItem("RefreshToken");
                await AsyncStorage.removeItem("username");
                window.location.reload();
            }
        }
    }

    public async get<T>(url: string, config?: AxiosRequestConfig<any> | undefined): Promise<AxiosResponse<T, any>> {
        try {
            Axios.defaults.headers.common = {
                Authorization: `Bearer ${await AsyncStorage.getItem("IdToken")}`,
            };
            const response = await Axios.get<T>(url, config);

            return response;
        } catch (error: any) {
            if (error.response && error.response.status === 401) {
                await this.handle401();
                return await Axios.get(url, config);
            }
            if (error.message && this.errorHandler) {
                this.errorHandler(error.message);
            }
            throw error;
        }
    }

    public async post<Res, Data>( // eslint-disable-line
        url: string,
        data?: Data | undefined,
        config?: AxiosRequestConfig<Data> | undefined
    ): Promise<AxiosResponse<Res, any>> {
        try {
            Axios.defaults.headers.common = {
                Authorization: `Bearer ${await AsyncStorage.getItem("IdToken")}`,
            };
            const response = await Axios.post<Res>(url, data, config);
            return response;
        } catch (error: any) {
            if (error.response && error.response.status === 401) {
                await this.handle401();
                return await Axios.post(url, data, config);
            }

            if (error?.response?.data?.message && this.errorHandler) {
                this.errorHandler(error?.response?.data?.message);
            }
            throw error;
        }
    }

    public async put<T>(
        url: string,
        data?: any,
        config?: AxiosRequestConfig<any> | undefined
    ): Promise<AxiosResponse<T, any>> {
        try {
            Axios.defaults.headers.common = {
                Authorization: `Bearer ${await AsyncStorage.getItem("IdToken")}`,
            };
            const result = await Axios.put<T>(url, data, config);
            return result;
        } catch (error: any) {
            if (error.response && error.response.status === 401) {
                await this.handle401();
                return await Axios.put(url, data, config);
            }
            throw error;
        }
    }
}
