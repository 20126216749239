import { Batch, Flavor, Manufacturer, Recipe } from "@juicy/models";
import { AddBatchDto, AddFlavorDto, AddManufacturerDto, AddRecipeDto, BatchDto, FlavorDto } from "@juicy/models/dto";
import { httpClient } from "../App";
import { DataListFilter, DataListOrderBy } from "./common/dataList/genericListAtom";
export class ApiService {
    recipe = {
        create: this.createRecipe.bind(this),
        list: this.listRecipes.bind(this),
        getById: this.getById.bind(this),
        edit: this.editRecipe.bind(this),
    };
    batch = {
        add: this.addBatch.bind(this),
        list: this.listBatches.bind(this),
        edit: this.editBatch.bind(this),
    };
    manufacturer = {
        create: this.addManufacturer.bind(this),
        edit: this.editManufacturer.bind(this),
        list: this.listManufacturers.bind(this),
    };
    flavors = {
        list: this.listFlavors.bind(this),
        create: this.addFlavor.bind(this),
        edit: this.editFlavor.bind(this),
        getOne: this.getOneFlavor.bind(this),
    };

    private constructListUrl(endpoint: string, page = 1, filter?: DataListFilter) {
        const rangeStart = (page - 1) * 30;
        const rangeEnd = (page - 1) * 30 + 29;

        let url = `/${endpoint}?range=[${rangeStart},${rangeEnd}]`;
        if (filter) {
            const filterForApi = {
                q: filter.search,
                minimumRating: filter.minimumRating,
            };
            url += `&filter=${encodeURIComponent(JSON.stringify(filterForApi))}`;
        }
        if (filter && filter.orderBy) {
            let order;
            switch (filter.orderBy) {
                case DataListOrderBy.Newest:
                    order = `["createdAt","DESC"]`;
                    break;
                case DataListOrderBy.Oldest:
                    order = `["createdAt","ASC"]`;
                    break;
                case DataListOrderBy.HighestRated:
                    order = `["rating","DESC"]`;
                    break;
                case DataListOrderBy.LowestRated:
                    order = `["rating","ASC"]`;
                    break;
            }
            url += `&sort=${encodeURIComponent(order)}`;
        }

        return url;
    }

    private async listRecipes(page = 1) {
        const rangeStart = (page - 1) * 30;
        const rangeEnd = (page - 1) * 30 + 29;
        const listResponse = await httpClient.get<Recipe[]>(`/recipes?range=[${rangeStart},${rangeEnd}]`);
        // list.forEach((recipe) => {
        //     Object.setPrototypeOf(recipe, new Recipe());
        // });
        return { list: listResponse.data, total: parseInt(listResponse.headers["content-range"]) };
    }
    private async createRecipe(recipeDto: AddRecipeDto) {
        const savedRecipe = await httpClient.put<Recipe>(`/recipes`, recipeDto);
        // Object.setPrototypeOf(savedRecipe, new Recipe());
        return savedRecipe.data;
    }
    private async editRecipe(recipe: Recipe) {
        const savedRecipeResponse = await httpClient.put<Recipe>(`/recipes/${recipe.userId}-${recipe.id}-${recipe.version}`, recipe);
        // Object.setPrototypeOf(savedRecipe, new Recipe());
        return savedRecipeResponse.data;
    }
    private async getById(id: number) {
        const recipeResponse = await httpClient.get<Recipe>(`/recipes/${id}`);
        // Object.setPrototypeOf(recipe, new Recipe());
        return recipeResponse.data;
    }

    private async addBatch(batch: AddBatchDto) {
        const createdBatchResponse = await httpClient.post<Batch, AddBatchDto>("/batches", batch);
        // Object.setPrototypeOf(createdBatch, new Batch());
        return createdBatchResponse.data;
    }

    private async listBatches(page = 1, filter?: DataListFilter) {
        const url = this.constructListUrl("batches", page, filter);
        const listResponse = await httpClient.get<Batch[]>(url);

        // list.forEach((recipe) => {
        //     Object.setPrototypeOf(recipe, new Recipe());
        // });
        return { list: listResponse.data, total: parseInt(listResponse.headers["content-range"]) };
    }

    private async editBatch(batch: BatchDto) {
        const savedBatchResponse = await httpClient.put<Batch>(
            `/batches/${batch.userId}-${batch.recipeId}-${batch.recipeVersion}-${batch.batchNumber}`,
            batch
        );
        // Object.setPrototypeOf(savedRecipe, new Recipe());
        return savedBatchResponse.data;
    }

    private async addManufacturer(manufacturerDto: AddManufacturerDto) {
        const createdBatchResponse = await httpClient.post<Manufacturer, AddManufacturerDto>("/manufacturers", manufacturerDto);
        // Object.setPrototypeOf(createdBatch, new Batch());
        return createdBatchResponse;
    }

    private async editManufacturer(id: number, manufacturerDto: AddManufacturerDto) {
        const savedManufacturerResponse = await httpClient.put<Manufacturer>(`/manufacturers/${id}`, manufacturerDto);
        // Object.setPrototypeOf(savedRecipe, new Recipe());
        return savedManufacturerResponse.data;
    }

    private async listManufacturers(page = 1, filter?: DataListFilter) {
        const url = this.constructListUrl("manufacturers", page, filter);
        const listResponse = await httpClient.get<Manufacturer[]>(url);

        // list.forEach((recipe) => {
        //     Object.setPrototypeOf(recipe, new Recipe());
        // });
        return { list: listResponse.data, total: parseInt(listResponse.headers["content-range"]) };
    }

    private async listFlavors(page = 1, filter?: DataListFilter) {
        const url = this.constructListUrl("flavors", page, filter);
        const listResponse = await httpClient.get<Flavor[]>(url);

        // list.forEach((recipe) => {
        //     Object.setPrototypeOf(recipe, new Recipe());
        // });
        return { list: listResponse.data, total: parseInt(listResponse.headers["content-range"]) };
    }

    private async addFlavor(flavorDto: AddFlavorDto) {
        const createdFlavorResponse = await httpClient.post<Flavor, AddFlavorDto>("/flavors", flavorDto);
        // Object.setPrototypeOf(createdBatch, new Batch());
        return createdFlavorResponse.data;
    }

    private async editFlavor(flavorDto: FlavorDto) {
        const savedFlavorResponse = await httpClient.put<Flavor>(`/flavors/${flavorDto.manufacturerId}-${flavorDto.id}`, flavorDto);
        // Object.setPrototypeOf(savedRecipe, new Recipe());
        return savedFlavorResponse.data;
    }

    private async getOneFlavor(id: number) {
        const flavorResponse = await httpClient.get<Flavor>(`/flavors/${id}`);
        return flavorResponse.data;
    }
}
