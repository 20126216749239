import React from "react";
import { View } from "react-native";
import {
    DEVICE_SIZES,
    CreateResponsiveStyle,
    useDeviceSize,
} from "rn-responsive-styles";

/**
 * This component will be used to center content in the middle of the screen.
 * for desktops and for mobile its just a wrapper with flex 1
 */
interface ContainerProps {
    children: JSX.Element | JSX.Element[];
}

export const Container: React.FC<ContainerProps> = ({ children }) => {
    const { container } = useStyles();
    const deviceSize = useDeviceSize();
    // console.log({ deviceSize });
    return <View style={container}>{children}</View>;
};

const useStyles = CreateResponsiveStyle(
    {
        container: {
            flex: 1,
        },
    },
    {
        [DEVICE_SIZES.EXTRA_SMALL_DEVICE]: {
            container: {
                paddingHorizontal: 4,
            },
        },
        [DEVICE_SIZES.SMALL_DEVICE]: {
            container: {
                paddingHorizontal: 16,
            },
        },
        [DEVICE_SIZES.MEDIUM_DEVICE]: {
            container: {
                width: 600,
                margin: "auto",
            },
        },
        [DEVICE_SIZES.EXTRA_LARGE_DEVICE]: {
            container: {
                width: 960,
                margin: "auto",
            },
        },
    }
);
