import { useNavigation } from "@react-navigation/native";
import { Button, Icon, Text } from "@ui-kitten/components";
import React from "react";
import { View } from "react-native";
import { DataList } from "../common/dataList/DataList";
import { DataListType, useDataList } from "../common/dataList/useDataList";
import { ScreenLayout } from "../common/layout/ScreenLayout";
import { BatchNavigation } from "../navigation";
import { BatchListItem } from "./BatchListItem";

const RenderBatchListItem = ({ item }: { item: any }) => (
    <View style={{ paddingVertical: 4 }}>
        <BatchListItem batch={item} />
    </View>
);

export const BatchListScreen: React.FC = () => {
    const { data, isLoading, refresh, loadMore, isLoadingMore } = useDataList(DataListType.Batch);

    const navigation = useNavigation<BatchNavigation>();

    return (
        <ScreenLayout scroll={false} title="Batches">
            <View
                style={{
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    height: 56,
                    alignItems: "center",
                }}
            >
                <Text category="h4" style={{ fontWeight: "600" }}>
                    BATCHES
                </Text>
                <Button
                    onPress={refresh}
                    appearance="ghost"
                    status="basic"
                    accessoryLeft={(props) => <Icon {...props} name="refresh" />}
                />
                <View style={{ flex: 1, alignItems: "flex-end" }}>
                    <Button
                        size="small"
                        accessoryLeft={(props) => <Icon {...props} name="plus" />}
                        onPress={() => navigation.navigate("BatchCreateScreen")}
                    />
                </View>
            </View>
            <DataList
                data={data}
                renderItem={RenderBatchListItem}
                isLoading={isLoading}
                isLoadingMore={isLoadingMore}
                loadMore={loadMore}
                refresh={refresh}
            />
        </ScreenLayout>
    );
};
